/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { BenefictsAndWhoDifferent, ExcusiveAttention, JsonLd, NormInfo, References, ServiceSteps, ServicesToOneClick } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom024: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-024-SCFI-2013 - Electrónicos';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org", "@type": "WebPage", "@id":
              "https://www.cecsatrade.mx/nom-024-scfi-2013", "url":
              "https://www.cecsatrade.mx/nom-024-scfi-2013", "name": "NOM-024-SCFI-2013",
            "description": `La NOM-024-SCFI-2013 regula el etiquetado de productos electrónicos, eléctricos y electrodomésticos en México. Asegura que los consumidores reciban información clara sobre su uso, origen, mantenimiento, advertencias de seguridad, y especificaciones técnicas, facilitando una compra informada y segura. `, "isPartOf": {
              "@id":
                "https://www.cecsatrade.mx/#/schema/WebSite/1"
            }, "datePublished":
              "2023-01-31T08:08:40-06:00", "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": { "@id": "https://www.cecsatrade.mx/assets/024.svg" }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="La NOM-024-SCFI-2013 regula el etiquetado de productos electrónicos, eléctricos y electrodomésticos en México. Asegura que los consumidores reciban información clara sobre su uso, origen, mantenimiento, advertencias de seguridad, y especificaciones técnicas, facilitando una compra informada y segura. "
        />
      </Helmet>
      <section className='norm-info'>
        <div className='sub-header'>
          <div className='sub-header__spacer'></div>
          <div className='sub-header__spacer'></div>
          {/* <a className='sub-header__button' href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a> */}
          <a
            className='sub-header__email'
            href="mailto:asesoria@cecsatrade.mx"
            target="_blank">
            <img loading="lazy" src="/img/mail.svg" alt="Mail" />
            |
            Mail de CECSA
          </a>
        </div>
        <h1 className='norm-info__title'>NOM-024-SCFI-2013 ELECTRÓNICOS</h1>
        <div className='norm-info__header norm-info__header--024'>
          <button onClick={() => {
            const element = document.getElementById("benefits");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
          }}>Beneficios</button><a href='https://wa.me/message/YZR3TYWZRARDH1' target='_blank'>Quiero cumplir con la NOM</a>
          <button onClick={() => {
            const element = document.getElementById("preguntas-frecuentes");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
            }
          }}>Preguntas frecuentes</button>
        </div>
        {/* <h1 className='norm-info__title'>NOM-024-SCFI-2013</h1>
        <h2>Información comercial para empaques,
          instructivos y garantías de los productos electrónicos, eléctricos y
          electrodomésticos.</h2>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text'>
            <h2>Objetivo</h2>
            <p>
              Esta Norma Oficial Mexicana tiene por objeto establecer los requisitos de
              información comercial que deben ostentar los empaques, instructivos y
              garantías para los productos electrónicos, eléctricos y electrodomésticos,
              así como sus accesorios y consumibles, destinados al consumidor final,
              cuando éstos se comercialicen en territorio de los Estados Unidos
              Mexicanos
            </p>
          </div>
          <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-024.svg" alt="NOM 015 Logo" />
        </div>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text'>
            <h2>Campo de aplicación</h2>
            <p>
              Esta Norma Oficial Mexicana es aplicable a los productos nuevos,
              reconstruidos, usados o de segunda mano, así como los repuestos,
              accesorios y consumibles que se comercialicen en el territorio de los
              Estados Unidos Mexicanos.
              <br />
              <br />
              Los repuestos, accesorios y consumibles, internos y externos, de productos
              electrónicos, eléctricos y electrodomésticos que estén destinados para
              expenderse a granel o para efectos de reposición dentro de garantía, no
              requieren del instructivo, garantía, ni de la información comercial a que
              se refiere esta Norma Oficial Mexicana, aun cuando sí requieran de las
              advertencias cuando sean productos peligrosos.
              <br />
              <br />
              Para efectos de los empaques, instructivos y garantías de los productos
              electrónicos, eléctricos y electrodomésticos, considerados como
              reconstruidos, usados o de segunda mano, no les es aplicable la
              <a
                href="https://dof.gob.mx/nota_detalle.php?codigo=4757871&fecha=12/07/1993#gsc.tab=0"
              >Norma Oficial Mexicana NOM-017-SCFI-1993.</a>
              <br />
              <h3 className="norm-info__container__sub-title" id="no-aplica">No aplica a:</h3>
              Los productos y sus repuestos, accesorios, consumibles, partes componentes
              internos y externos de productos electrónicos y electrodomésticos para
              efectos de respaldo o reposición dentro de la garantía en el punto de
              entrada al país y no estarán sujetos a la evaluación de conformidad por
              parte de la unidad de inspección.
              <br />
              <h3
                className="norm-info__container__sub-title"
                id="referencias"
              >Referencias</h3>
              Esta Norma Oficial Mexicana se complementa con las siguientes Normas
              Oficiales Mexicanas vigentes o las que las sustituyan:
              <br /><a
                href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002"
              >NOM-008-SCFI-2002</a>, "Sistema General de Unidades de Medida", publicada
              en el Diario Oficial de la Federación el 27 de noviembre de 2002.
              <br /><a
                href="https://dof.gob.mx/nota_detalle.php?codigo=4757871&fecha=12/07/1993#gsc.tab=0"
              >NOM-017-SCFI-1993</a>, "Información comercialâEtiquetado de artículos
              reconstruidos, usados o de segunda mano, de segunda línea, discontinuados
              y fuera de especificaciones", publicada en el Diario Oficial de la
              Federación el 29 de octubre de 1993.
            </p>
          </div>
        </div>
        <blockquote cite="https://dof.gob.mx/nota_detalle.php?codigo=5033112&fecha=17/04/2008#gsc.tab=0">
          <div className="norm-info__container__image-container">

            <img
              loading="lazy"
              className="norm-info__container__image-container__image"
              src="/img/024-image-1.svg"
              alt="NOM Logo"
            />
            <div>
              <p className="norm-info__container__text">
                <h2 className="norm-info__container__sub-title">Definiciones</h2>
                <dl>

                  <dt>Accesorio</dt>
                  <dd>Es la parte de un producto electrónico, eléctrico o
                    electrodoméstico que se adquiere en forma separada y opcional y
                    que puede o no formar parte del producto original y que puede, o
                    no, requerir energía eléctrica para cumplir su función.</dd>

                  <dt>Advertencia</dt>
                  <dd>Información escrita o por medio de símbolos que invitan al
                    consumidor a poner atención sobre los riesgos durante el uso del
                    aparato.</dd>

                  <dt>Artículo reconstruido</dt>
                  <dd>Es aquel artículo que se ha vuelto a construir o es renovado o
                    reparado, sustituyéndole las piezas defectuosas o de mal
                    funcionamiento por piezas nuevas y que se expende al público en
                    general.</dd>

                </dl>
              </p>
            </div>
          </div>
        </blockquote>
        <blockquote cite="https://dof.gob.mx/nota_detalle.php?codigo=5033112&fecha=17/04/2008#gsc.tab=0">
          <div className="norm-info__container__image-container">
            <div>
              <p className="norm-info__container__text">
                <dl>

                  <dt>Artículo usado o de segunda mano</dt>
                  <dd>Es aquel artículo que ya ha sido usado y es puesto a la venta del
                    público en general sin reconstruir o renovar.</dd>

                  <dt>Consumible</dt>
                  <dd>Es aquel elemento que se utiliza en un producto electrónico,
                    eléctrico o electrodoméstico y que sufre un desgaste o se agota
                    parcial o totalmente por su propia función de uso.</dd>

                  <dt>Consumidor</dt>
                  <dd>Es la persona física o moral que adquiere, realiza o disfruta como
                    destinatario final bienes, productos o servicios. No se considera
                    consumidor quien adquiera, almacene, utilice o consuma bienes o
                    servicios con objeto de integrarlos a procesos de producción,
                    transformación, comercialización o prestación de servicios a
                    terceros.</dd>

                </dl>
              </p>
            </div>

            <img
              loading="lazy"
              className="norm-info__container__image-container__image"
              src="/img/024-image-2.svg"
              alt="NOM Logo"
            />
          </div>
        </blockquote>

        <blockquote cite="https://dof.gob.mx/nota_detalle.php?codigo=5033112&fecha=17/04/2008#gsc.tab=0">
          <div className="norm-info__container__image-container">
            <div>
              <p className="norm-info__container__text">
                <dl>

                  <dt>Empaque</dt>
                  <dd>Es el medio por el cual se evitan daños al producto o
                    productos, siempre y cuando éste sea empleado en forma apropiada
                    durante y a través de su transportación y manejo.</dd>

                  <dt>Etiqueta</dt>
                  <dd>Es cualquier rótulo, marbete, inscripción, imagen u otra
                    materia descriptiva o gráfica escrita, impresa, marcada, grabada
                    en alto o bajo relieve, adherida o sobrepuesta al producto, a su
                    envase o empaque, o cuando no sea posible por las
                    características del producto o su envase, al embalaje.</dd>

                  <dt>Garantía</dt>
                  <dd>Es el documento mediante el cual el fabricante y/o importador
                    se compromete a respaldar por un tiempo determinado el producto
                    o, en su caso, el accesorio, parte o componente contra cualquier
                    defecto de los materiales y/o mano de obra empleados en la
                    fabricación de los mismos.</dd>

                  <dt>Instructivo</dt>
                  <dd>Es el documento, incluido aquel presentado en medios de
                    almacenamiento de información electrónica, que contiene las
                    instrucciones de uso, manejo, precauciones que se deben tener y,
                    en su caso, las advertencias y datos para la instalación,
                    cuidado y mantenimiento del producto, dirigidas al consumidor.</dd>

                  <dt>Medio de almacenamiento de información electrónica</dt>
                  <dd>Es aquel en el cual puede ser almacenada cualquier tipo de
                    información del producto. Estos pueden ser discos duros, discos
                    flexibles, CD's, DVD, memorias electrónicas, direcciones de páginas
                    de Internet o similares.</dd>

                  <dt>Producto a granel</dt>
                  <dd>Producto que debe pesarse, contarse o medirse en presencia del
                    consumidor por no encontrarse preenvasado al momento de su venta.</dd>

                  <dt>Producto eléctrico</dt>
                  <dd>Equipo que se utiliza para propósitos de generación, conversión,
                    transmisión, distribución o utilización de energía eléctrica, tales
                    como máquinas, transformadores, aparatos, instrumentos de medición,
                    dispositivos de protección, equipo para alambrado de sistemas y
                    aparatos. Nota. Esto incluye subensambles, equipo (tales como
                    tabletas de circuitos impresos, conectores, gabinetes) e
                    instalaciones, tal como esté definido bajo contrato.</dd>

                  <dt>Producto electrodoméstico</dt>
                  <dd>Aparato eléctrico con o sin elementos calefactores, operados por
                    motor o accionados magnéticamente para uso doméstico o similar, que
                    utilizan para su alimentación la energía eléctrica de la red
                    pública, así como de otras fuentes de energía como pilas, baterías,
                    acumuladores o autogeneración.
                  </dd>

                  <dt>Producto electrónico</dt>
                  <dd>Equipo eléctrico cuya función principal se lleva a cabo por el uso
                    de componentes a través de la conducción de electrones o iones en
                    movimiento en semiconductores, en un vacío o en un gas.</dd>

                  <dt>No aplica</dt>
                  <dd>A los productos y sus repuestos, accesorios, consumibles, partes
                    componentes internos y externos de productos eléctronicos y
                    electrodomésticos para efectos de respaldo o reposición dentro de la
                    garantía en el punto de entrada al país y no estrán sujetos a la
                    evaluación de conformidfad por parte de la unidad de inspección.</dd>

                </dl>
              </p>
            </div>
          </div>
        </blockquote> */}
        <div className='norm-info__image-container'>
          {/* <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-003-asset.svg" alt="NOM 003 Logo" /> */}
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Sé el primero en saber...</h2>
            <br />
            <p>
              La  norma de <b>productos electrónicos, eléctricos y electrodomésticos</b> establece los requisitos de información comercial para el buen etiquetado de los empaques, instructivos y garantías de los productos que forman parte de la <b>NOM 024</b> para su consumidor final siendo comercializado en el país.
              <br />
              <br />
              Esta <b>Norma Oficial Mexicana</b> da a conocer las regulaciones para garantizar al consumidor la calidad para proteger su seguridad en estos <b>productos</b>, ya sean de origen nacional o de procedencia extranjera.
            </p>
          </div>
        </div>
        <div className='norm-info__image-container norm-info__image-container--black'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center norm-info__image-container__text--small-margin'>
            <p style={{ textAlign: 'left' }}>
              La <b>NOM-024-SCFI-2013</b> regula el <b>etiquetado de productos electrónicos, eléctricos y electrodomésticos</b> en México. Asegura que los consumidores reciban información clara sobre su uso, origen, mantenimiento, advertencias de seguridad, y especificaciones técnicas, facilitando una compra informada y segura.
            </p>
            <button onClick={() => {
              const element = document.getElementById("benefits");

              if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
              }
            }}>Beneficios</button>
          </div>
          <div className='norm-info__image-container__img-container'>
            <img className='norm-info__image-container__img-container__image' loading="lazy" src="/img/freidora.png" alt="freidora" />
          </div>
        </div>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Entrada en vigor</h2>
            <br />
            <p>
              La <b>NOM-024-SCFI-2013, Información comercial para empaques, instructivos y garantías de los productos electrónicos, eléctricos y electrodomésticos</b>. Entró en vigor el 10 de noviembre de 2013 pero fue publicada en el <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5309980&fecha=12/08/2013#gsc.tab=0" target='_blank'>Diario Oficial de la Federación</a> el 08 de diciembre de 2013.
            </p>
            <br />
            <a className='norm-info__image-container__anchorage' href="https://www.dof.gob.mx/nota_detalle.php?codigo=5033112&fecha=17/04/2008#gsc.tab=0" target='_blank'>Más información de esta NOM</a>
          </div>
        </div>
        <img className='norm-info__ellipse' src="/img/ellipse-black.svg" alt="ellipse" />
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Aplicación de la NOM-015</h2>
            <br />
            <p>
              <div className='norm-info__image-container__aplications-015'>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-024-1.svg" alt="aplication-024-1" />
                  <span><b>Productos nuevos</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-024-2.svg" alt="aplication-024-2" />
                  <span><b>Productos reconstruidos</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-024-3.svg" alt="aplication-024-3" />
                  <span><b>Productos seminuevos</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-024-4.svg" alt="aplication-024-4" />
                  <span><b>Repuestos a granel</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-024-5.svg" alt="aplication-024-5" />
                  <span><b>Accesorios y consumibles</b></span>
                </div>
              </div>
            </p>
            <br />
            <p>
              Los productos electrónicos, eléctricos y electrodomésticos que son reconstruidos, usados o de segunda mano no están sujetos a la NOM para empaques, instructivos y garantías.
            </p>
          </div>
        </div>
        <ExcusiveAttention />
        <div className='norm-info__image-container norm-info__image-container--blue-050'>
          <img className='norm-info__image-container__image-background-1' loading="lazy" src="/img/background-circle-blue-1.svg" alt="backghround 1" />
          <div className='norm-info__image-container__text'>
            <h2>La NOM-024-SCFI- 2013 no aplica a:</h2>
            <br />
            <p>La presente <b>Norma Oficial Mexicana</b> no es aplicable a lo siguiente:</p>
            <br />
            <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
              <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/repuestos.svg" alt="repuestos" />
            </div>
            <br />
            <p>Los productos electrónicos y electrodomésticos, junto con sus repuestos y accesorios, destinados a respaldo o reposición bajo garantía.</p>
            <br />
          </div>
          <img className='norm-info__image-container__image-background-2' loading="lazy" src="/img/background-circle-blue-2.svg" alt="backghround 2" />
        </div>
        <div className='norm-info__image-container norm-info__image-container--black norm-info__image-container--margin norm-info__image-container--050' style={{ marginBottom: '30rem', overflow: 'visible', zIndex: '7' }}>
          <h2>DEFINICIONES</h2>
          <div className="norm-info__050-definitions__item">
            <span>Garantía</span>
            <p>El fabricante o importador garantiza el producto, accesorio o componente, cubriendo cualquier defecto en los materiales o la mano de obra utilizada.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Producto eléctrico</span>
            <p>Equipo utilizado para generar, convertir, transmitir, distribuir o usar energía eléctrica, como máquinas, transformadores, aparatos y sistemas de alambrado.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Producto electrodoméstico </span>
            <p>Aparato eléctrico, con o sin calefactores, operado por motor que usa energía de la red pública o de otras fuentes como pilas, baterías o autogeneración.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Producto electrónico</span>
            <p>Equipo eléctrico que opera mediante el movimiento de electrones o iones en semiconductores, vacío o gas. Incluye artículos reconstruidos o reparados.</p>
          </div>
          <h2>CONTENIDO DE LA ETIQUETA</h2>
          <br />
          <p className='norm-info__image-container__text-center'>
            <br />
            <br />
            La información contenida en la etiqueta debe garantizar que el consumidor esté informado de manera clara y precisa, promoviendo decisiones de compra seguras
            <br />
            <br />
          </p>
          <br />
          <div className="norm-info__015-definitions__item norm-info__015-definitions__item--images">
            <img src="/img/024-logo-1.png" alt="024-logo-1" />
            <img src="/img/024-logo-2.png" alt="024-logo-2" />
            <img src="/img/024-logo-3.png" alt="024-logo-3" />
            <img src="/img/024-logo-4.png" alt="024-logo-4" />
          </div>
          <br />
          <div className="norm-info__050-definitions__item">
            <span>Información esencial</span>
            <p>Debe incluir el nombre del producto o una imagen del mismo, y los datos del fabricante o importador (nombre, dirección).</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>País de origen</span>
            <p>Incluir la leyenda "Hecho en..." o similar, indicando el país donde fue fabricado.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Especificaciones eléctricas</span>
            <p>Mostrar claramente voltaje, consumo de potencia y frecuencia, para garantizar el uso adecuado.</p>
          </div>
          <div className="norm-info__015-definitions__item norm-info__015-definitions__item--column">
            <img src="/img/audifonos.png" alt="audifonos" />
            <div className="norm-info__015-definitions__item__container">
              <p>
                <b>Productos usados o reconstruidos</b>
                <br />
                Si es reconstruido o de segunda mano, esta información debe ser visible con una letra más grande que el resto.
                <br />
                <br />
                <b>Repuestos y accesorios</b>
                <br />
                Deben llevar al menos el nombre, fabricante, y país de origen, garantizando autenticidad y compatibilidad.
              </p>
            </div>
          </div>
          <img className='norm-info__050-definitions__footer' loading="lazy" src="/img/definitions-050-footer.svg" alt="footer" />
        </div>
        <BenefictsAndWhoDifferent
          title={'CERTIFICA TUS PRODUCTOS'}
          text={'Contactanos, nosotros te apoyamos en cada paso que debes realizar para cumplir conforme lo establecido de manera nacional en materia de comercial dentro de la industria.'}
        />
        <References
          items={[
            (
              <li>
                <img src="/img/list-item-024.png" alt="airpods" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002#gsc.tab=0" target='_blank'>NOM-008-SCFI-2002</a>, "Sistema General de Unidades de Medida".
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-024.png" alt="airpods" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=4757871&fecha=12/07/1993#gsc.tab=0" target='_blank'>NOM-017-SCFI-1993</a>, "Información comercial-Etiquetado de artículos reconstruidos, usados o de segunda mano, discontinuados y fuera de especificaciones¨.
              </li>
            )
          ]}
        />
        <NormInfo
          DOFDate='12/08/2013'
          VigorDate='11/10/2013'
          responsible='SECRETARIA DE ECONOMIA '
        />
        <div className='norm-info__questions' id='preguntas-frecuentes'>
          <h2 className="norm-info__container__sub-title">Preguntas frecuentes</h2>
          <ul className="accordion norm-info__container__not-transform-ul" id="accordion">
            <li className="accordion-item">
              <div id="heading1">
                <button className="accordion-button norm-info__container__accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                  ¿Qué son las garantías dentro de esta NOM?
                </button>
              </div>
              <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Es el documento mediante el cual el fabricante y/o importador se compromete a respaldar por un tiempo determinado el producto o, en su caso, el accesorio, parte o componente contra cualquier defecto de los materiales y/o mano de obra empleados en la fabricación de los mismos.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                  ¿Por qué son importantes las garantías?
                </button>
              </div>
              <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Las garantías cubren todas las piezas, componentes y mano de obra del producto, lo que obliga a fabricantes e importadores a reemplazar sin costo cualquier parte defectuosa. Sin embargo, no están obligados a hacerlo si:
                  <br />
                  <br />
                  &#8226; El producto fue usado en condiciones distintas a las normales.
                  <br />&#8226; No se siguieron las indicaciones del instructivo.
                  <br />&#8226; El producto fue alterado o reparado por personas no autorizadas.
                  <br />
                  <br />

                  Esto garantiza que el producto funcione adecuadamente bajo las condiciones recomendadas, protegiendo tanto al consumidor como al fabricante.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading3">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                  ¿Cómo se clasifican estos productos?
                </button>
              </div>
              <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Los productos electrónicos, eléctricos y electrodomésticos se clasifican en:

                  <br /><br />&#8226; Productos peligrosos por su diseño.
                  <br />&#8226; Productos peligrosos por su uso.
                  <br />&#8226; Productos para lugares peligrosos.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading4">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                  ¿Hay excepciones?
                </button>
              </div>
              <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Quedan exentos los equipos altamente especializados que no se vendan al público directamente y cuya comercialización no está destinada al uso doméstico, sino para fines especiales de acuerdo con las necesidades expresadas en un contrato donde se incluya la información comercial, garantía e instalación, tampoco requieren de instructivos, etiquetas, ni advertencias por ser instalados por personal técnico especializado del proveedor.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading5">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                  ¿Hay excepciones?
                </button>
              </div>
              <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Quedan exentos los equipos altamente especializados que no se vendan al público directamente y cuya comercialización no está destinada al uso doméstico, sino para fines especiales de acuerdo con las necesidades expresadas en un contrato donde se incluya la información comercial, garantía e instalación, tampoco requieren de instructivos, etiquetas, ni advertencias por ser instalados por personal técnico especializado del proveedor.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading6">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                  ¿Qué productos están sujetos a la NOM-024?
                </button>
              </div>
              <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Están sujetos los productos electrónicos, eléctricos y electrodomésticos, así como sus accesorios y consumibles.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading7">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                  ¿Es obligatorio que la información esté en español?
                </button>
              </div>
              <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Sí, la información comercial debe estar en español, sin perjuicio de que también pueda estar en otros idiomas.
                  <br />
                  <br />
                  Esto es obligatorio para asegurar que los consumidores mexicanos comprendan toda la información importante sobre el producto.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading8">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                  ¿Qué tipo de sanciones se aplican por no cumplir con la NOM-024?
                </button>
              </div>
              <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordion">
                <div className="accordion-body">
                  De acuerdo a la Ley  Federal de la Metrología y Normalización, en el articulo 112, nos dice que si un producto no cumple con la NOM puede generar multas, clausura temporal o definitiva, que podrá ser parcial o total, arresto hasta por treinta y seis horas, suspensión o revocación de la autorización, aprobación, o registro según corresponda y suspensión o cancelación del documento donde consten los resultados de la evaluación de la conformidad, así como de la autorización del uso de contraseñas y marcas registradas.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading9">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                  ¿Qué pasa si detienen mi producto en aduana?
                </button>
              </div>
              <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordion">
                <div className="accordion-body">
                  En primer lugar, deberás de solicitar la fracción arancelaria del producto. Una vez que tengas la fracción arancelaria y corrobores que le solicitan el cumplimiento de la <b>NOM-024-SCFI-2013</b> deberás de contactar a una <a href="https://www.cecsatrade.mx/" target='_blank'>Unidad de Inspección</a> para obtener una Constancia de Inspección.<br />
                  Debido a que el producto no puede etiquetarse en México, deberás de etiquetar en la aduana o regresar el producto con tu proveedor para que sea etiquetado.<br />
                  Te recomendamos analizar los costos de cada situación y evaluarlos.
                  <br /><br />
                  Una vez que nos contactes, te brindaremos la asesoría y apoyo para que puedas liberar tu producto de la aduana. El proceso será rápido, cada minuto cuenta, por lo que te dejamos nuestro correo (<a href="mailto:asesoria@cecsatrade.mx">contacto@cecsatrade.mx</a>) y número de contacto (<a href="tel:+525650190728">56 5019 0728</a>)
                </div>
              </div>
            </li>
          </ul>
        </div>
        <img className='norm-info__ellipse' src="/img/ellipse-blue.svg" alt="ellipse" />
        <ServicesToOneClick />
        <ServiceSteps
          withNewsLetter={true}
        />
      </section>
    </>
  )
}

export default Nom024