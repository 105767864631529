/* eslint-disable react/jsx-no-target-blank */
import { useContext, useEffect, useState } from 'react';
import { AllDigital, Button, DigitalAdvantages, JsonLd, Newsletter, OnlineServices, QuestionsService, ServiceSteps } from '../../components';
import { AppContext } from '../../context/AppContext';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Revision: React.FC = () => {
  const {
    setModalOpen,
    modalOpen
  } = useContext(AppContext);

  const [imageModal, setImageModal] = useState<'fundaments' | 'beneficts' | ''>('');

  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'Revisión de etiquetado';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "https://www.cecsatrade.mx/revision-service",
            "url": "https://www.cecsatrade.mx/revision-service",
            "name": "Constancia",
            "description": "Servicio emitido a importadores: Cuando quieran cumplir con el etiquetado antes del despacho aduanero, es requisito presentar la mercancía ya etiquetada.",
            "isPartOf": {
              "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
            },
            "datePublished": "2023-01-31T08:08:40-06:00",
            "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": {
              "@id": "https://www.cecsatrade.mx/img/icons/revision.svg"
            }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="Documento que respalda que tú producto se encuentre correctamente etiquetado. Evita multas y sanciones en la aduana"
        />
      </Helmet>
      <section className='revision'>
        <div className='sub-header'>
          <div className='sub-header__spacer'></div>
          <div className='sub-header__spacer'></div>
          {/* <a className='sub-header__button' href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a> */}
          <a
            className='sub-header__email'
            href="mailto:asesoria@cecsatrade.mx"
            target="_blank">
            <img loading="lazy" src="/img/mail.svg" alt="Mail" />
            |
            Mail de CECSA
          </a>
        </div>
        <h1>REVISIÓN DE ETIQUETADO </h1>
        <img src="/img/revision-image.png" alt="revision" className='revision__header-image' />
        <div className='norm-info__image-container'>
          {/* <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-003-asset.svg" alt="NOM 003 Logo" /> */}
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <p>
              Si eres nuevo en importaciones o ya tienes experiencia en ello. ¡Solicita una cotización personalizada con los expertos!
            </p>
          </div>
        </div>
        <Button
          text={'Solicitar cotización'}
          onClick={() => setModalOpen(true)}
          type={'black'}
          className='revision__header-button'
        />
        <br />
        <div className='revision__info'>
          <div className='revision__info__info-container'>
            <div className='revision__info__text'>
              <h2>REVISIÓN DE ETIQUETADO </h2>
              <p>
                Las <b>autoridades en México</b> cuentan con las facultades para <b>revisar el etiquetado</b> de productos de manera aleatoria, tanto en establecimentios como supermercados, farmacias, tienda de coneniencia, etc. Por lo que es importante estar al día con las regulaciones y evitar que te envíen un requerimiento para modificar el etiquetado de tu producto.
                <br />
                <br />
                Muchos de los <b>productos en México</b> cuentan con publicidad engañosa o que no pueden comprobar, por lo que nuestro grupo de expertors puede decirte que es lo que puedes o no colocar en las <b>etiquetas de tus productos</b>.
                <br /><br />De esta manera podrás aventajar a tu competencia.
              </p>
            </div>
            <div className='revision__info__image'>
              <img src="/img/importation-revision.png" alt="importation revision" />
            </div>
          </div>
        </div>
        <div className='revision__list'>
          <h2>Realiza tu Revisión de Etiquetado  y obtén ...</h2>
          <div className='revision__list__container'>
            <div className='revision__list__container__item'>
              <p>
                En <b>CECSA</b> ya no tendrás que preocuparte por multas y retrasos en la aduana. Tus mercancías podrán cruzar libremente a México evitando demoras por algún incumplimiento de etiquetado.
              </p>
              <img src="/img/charging.svg" alt="charging" />
            </div>
            <div className='revision__list__container__item'>
              <img src="/img/documents-check.svg" alt="documents-check" />
              <p>
                Etiqueta correctamente tus mercancías con base a las <a href="https://blog.cecsatrade.mx/normas-oficiales-mexicanas-vs-normas-mexicanas-conoces-la-diferencia/" target='_blank'>Normas Oficiales Mexicanas</a>  antes de que tu proveedor las mande a la aduana y evita asi posibles retrasos.

              </p>
            </div>
          </div>
          <p className='revision__list__text'>
            <div className='revision__list__text__buttons'>
              <Button
                text={'Fundamento legal'}
                onClick={() => setImageModal('fundaments')}
                type={'black'}
                className='constancy__header-button'
              />
              <Button
                text={'Preguntas frecuentes'}
                onClick={() => {
                  const element = document.getElementById("preguntas-frecuentes");

                  if (element) {
                    element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
                  }
                }}
                type={'black'}
                className='revision__header-button'
              />
              <Button
                text={'Conoce los beneficios'}
                onClick={() => setImageModal('beneficts')}
                type={'black'}
                className='constancy__header-button'
              />
            </div>
          </p>
        </div>
        {
          imageModal !== '' && (
            <div className='image-modal' onClick={() => setImageModal('')}>
              {/* <div className='image-modal__background' onClick={() => setImageModal('')}></div> */}
              <Button
                text={'Cerrar'}
                onClick={() => setImageModal('')}
                type={'ligth'}
                icon='close.svg'
                className='image-modal__close-button'
                iconClassName='image-modal__close-button__close-icon'
              />
              <img src={`/img/revision-${imageModal}.png`} alt={imageModal} />
            </div>
          )
        }
        <div className='revision__legal'>
          <h2 style={{ width: '100%', textAlign: 'center' }}>CECSA te ofrece...</h2>
          <div className='revision__legal__section-2'>
            <div className='revision__legal__section-2__item'>
              <img src="/img/legal-1.svg" alt="legal-1" />
              <h3>Presencia Nacional</h3>
              <p>Contamos con un equipo en todo México listo para apoyarte con inspecciones de etiquetado.</p>
            </div>
            <div className='revision__legal__section-2__item'>
              <img src="/img/legal-2.svg" alt="legal-2" />
              <h3>Trabajamos con tu agente aduanal</h3>
              <p>Facilita el trabajo y gestión de los requisitos que te solicita la aduana.</p>
            </div>
            <div className='revision__legal__section-2__item'>
              <img src="/img/legal-3.svg" alt="legal-3" />
              <h3>Acreditación y Aprobación</h3>
              <p>En CECSA, contamos con las licencias necesarias según la Ley de Infraestructura de la Calidad. ¡Somos experiencia!
              </p>
            </div>
          </div>
        </div>
        <AllDigital />
        <DigitalAdvantages />
        <OnlineServices />
        {/* <div className='revision__fundament'>
          <h2>Fundamentos</h2>
          <p className='revision__fundament__text'>
            ACUERDO por el que la Secretaría de Economía emite Reglas y criterios de carácter general en materia de comercio exterior.
            <br />
            <br />
            2.4.8 Para las mercancías que se listan en el numeral 3 del Anexo 2.4.1 del presente ordenamiento, únicamente se exigirá que las etiquetas o los medios adheribles permitidos, contengan la información establecida en la NOM correspondiente, y que al momento de su introducción al territorio nacional se encuentren adheridas, pegadas, cosidas, colgadas o colocadas en las mercancías como se establezca en cada una de las normas, de tal modo que impida su desprendimiento inmediato, y asegure su permanencia en las mismas hasta llegar al usuario.
            <br />
            <br />
            I. Presentar a despacho aduanero, acompañadas del documento original o copia de la constancia de conformidad expedida por una unidad de verificación o de inspección acreditada y aprobada en los términos de la Ley de la Infraestructura de la Calidad
            <br />
            <br />
            <b>Constancia</b> <br />
            Documento que se emite a los productores, fabricantes, importadores, comercializadores o prestadores de servicios como resultado de la evaluación de la conformidad realizada a una etiqueta en el que se evidencia el cumplimiento o no cumplimiento de los requisitos establecidos en las Normas Oficiales Mexicanas, cuando sea aplicable de conformidad con el procedimiento establecido en el Acuerdo por el que la Secretaría de Economía emite reglas y criterios de carácter general en materia de Comercio Exterior.

          </p>
          <div className='revision__fundament__image-container'>
            <img src="/img/fundament.svg" alt="Basis" className='revision__fundament__image-container__image' />
          </div>
        </div> */}
        <ServiceSteps
          withNewsLetter={false}
        />
        <div className={'benefits-organisms benefits-organisms--blue'}>
          <h2>ORGANISMOS INVOLUCRADOS</h2>
          <div className='benefits-organisms__conteiner'>
            <div className={'benefits-organisms__conteiner__item benefits-organisms__conteiner__item'} style={{ backgroundColor: '#ffffff' }}>
              <img src="/img/ema.svg" alt="map location" />
            </div>
            <div className={'benefits-organisms__conteiner__item benefits-organisms__conteiner__item'} style={{ backgroundColor: '#ffffff' }}>
              <img src="/img/dgn.svg" alt="map location" />
            </div>
            <div className={'benefits-organisms__conteiner__item benefits-organisms__conteiner__item'} style={{ backgroundColor: '#ffffff' }}>
              <img src="/img/SE.svg" alt="map location" />
            </div>
            <div className={'benefits-organisms__conteiner__item benefits-organisms__conteiner__item'} style={{ backgroundColor: '#ffffff' }}>
              <img src="/img/PROFECO.svg" alt="map location" />
            </div>
            <div className={'benefits-organisms__conteiner__item benefits-organisms__conteiner__item'} style={{ backgroundColor: '#ffffff' }}>
              <img src="/img/SALUD.svg" alt="map location" />
            </div>
          </div>
        </div>
        <QuestionsService
          questions={[
            {
              question: '¿Cuánto cuesta  una revisión de etiquetado?',
              response: <>Dependerá del numero de modelos o constancias que requieras, acércate con uno de nuestros especialistas para que podamos darte una atención personalizada.</>
            },
            {
              question: '¿Qué necesito para obtener una revisión de etiquetas?',
              response: <>Requiere darte de alta con nosotros, a través de un contrato de servicios. Una vez que el contrato este firmado podrás enviar la información de tu producto como puede ser, imágenes, archivos PDF, archivos editables, medidas del envase, etc.</>
            },
            {
              question: '¿Cuánto tiempo toma obtener una revisión de etiquetado?',
              response: <>Nuestros tiempos varían en función a la cantidad de etiquetas que requieras revisión. Sugerimos preguntar a un asesor.</>
            },
            {
              question: '¿Puedo solicitar una revisón urgente?',
              response: <>Si, una vez que ya tengas el contrato firmado puedes solicitar el servicio urgente, el cual se entrega al siguiente día hábil.</>
            },
            {
              question: '¿Puedo tramitar una constancia posterior a la revisión?',
              response: <>Si, puedes solicitar una constancia posterior a la revisión, solamente que se cobraría un extra por la emisión de la constancia</>
            },
            {
              question: '¿Necesito tramitar una revisión por cada producto?',
              response: <>Si, ya que la revisión ampara 1 producto y en caso de que tengas varias presentaciones es requerido que las evaluemos todas para no dejar huecos y que cumplas con la norma en todos sus requisitos.</>
            },
            {
              question: '¿Cuántas ceces pueden revisar mi producto por cada servicio?',
              response: <>Una vez liquidado el servicio tienes derecho a 3 revisiones por cada producto.</>
            },
            {
              question: '¿Quién respalda el trabajo de CECSA?',
              response: <>CECSA cuenta con acreditación por parte de la Entidad Mexicana de Acreditación y cuenta con Aprobación por parte de la Dirección General de Normas de la Secretaría de Economía, lo que permite que tengamos conocimientos y competencia técnica para poderte resolver tus dudas y que te guiemos por el mejor camino para que cumplas con los requisitos regulatorios de las Normas Oficiales Mexicanas</>
            }
          ]}
        />
        <Newsletter />
      </section>
    </>
  )
}

export default Revision