import { useEffect, useRef } from 'react';
import './styles.scss';
import Button from '../Button';

const DigitalAdvantages: React.FC = () => {
  const circle1 = useRef<HTMLDivElement>(null);
  const circle2 = useRef<HTMLDivElement>(null);
  const circle3 = useRef<HTMLDivElement>(null);
  const circle4 = useRef<HTMLDivElement>(null);
  const circle5 = useRef<HTMLDivElement>(null);
  const circle6 = useRef<HTMLDivElement>(null);
  const circle7 = useRef<HTMLDivElement>(null);
  const line1 = useRef<HTMLDivElement>(null);
  const line2 = useRef<HTMLDivElement>(null);
  const line3 = useRef<HTMLDivElement>(null);
  const line4 = useRef<HTMLDivElement>(null);
  const line5 = useRef<HTMLDivElement>(null);
  const line6 = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const cambiarClase = () => {
      if (circle1.current) {
        circle1.current.classList.toggle('digital-advantages__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle1.current) {
              circle1.current.classList.toggle('digital-advantages__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle2.current) {
        circle2.current.classList.toggle('digital-advantages__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle2.current) {
              circle2.current.classList.toggle('digital-advantages__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle3.current) {
        circle3.current.classList.toggle('digital-advantages__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle3.current) {
              circle3.current.classList.toggle('digital-advantages__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle4.current) {
        circle4.current.classList.toggle('digital-advantages__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle4.current) {
              circle4.current.classList.toggle('digital-advantages__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle5.current) {
        circle5.current.classList.toggle('digital-advantages__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle5.current) {
              circle5.current.classList.toggle('digital-advantages__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle6.current) {
        circle6.current.classList.toggle('digital-advantages__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle6.current) {
              circle6.current.classList.toggle('digital-advantages__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle7.current) {
        circle7.current.classList.toggle('digital-advantages__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle7.current) {
              circle7.current.classList.toggle('digital-advantages__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (line1.current) {
        line1.current.classList.toggle('digital-advantages__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line1.current) {
              line1.current.classList.toggle('digital-advantages__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      if (line2.current) {
        line2.current.classList.toggle('digital-advantages__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line2.current) {
              line2.current.classList.toggle('digital-advantages__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      if (line3.current) {
        line3.current.classList.toggle('digital-advantages__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line3.current) {
              line3.current.classList.toggle('digital-advantages__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      if (line4.current) {
        line4.current.classList.toggle('digital-advantages__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line4.current) {
              line4.current.classList.toggle('digital-advantages__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      if (line5.current) {
        line5.current.classList.toggle('digital-advantages__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line5.current) {
              line5.current.classList.toggle('digital-advantages__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      if (line6.current) {
        line6.current.classList.toggle('digital-advantages__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line6.current) {
              line6.current.classList.toggle('digital-advantages__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      timeoutRef.current = setTimeout(cambiarClase, 16000);
    };

    cambiarClase();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [timeoutRef]);

  return (
    <section className='digital-advantages'>
      <h2>Ventajas de trabajar digital</h2>
      <div className='digital-advantages__text'>
        <p>
          Olvidate de los correos electrónicos de ida y vuelta, de la administración de contratos y la recopilación de documentos.
          <br />
          Automatizamos todos nuestros procesos para que puedas darle seguimiento en línea 24 horas.
          <br />
          <br />
          Obtén tu solicitud de servicios al instante.
          <br />
          <br />
          Obtén sus folios al instante
          <br />
          <br />
          Obtén la fecha de entrega al instante
        </p>
        <div className='digital-advantages__animation-container'>
          <span className='digital-advantages__animation-container__title'>Seguimiento</span>
          <div className='digital-advantages__animation-container__steps'>
            <div className='digital-advantages__animation-container__steps__container'>
              <div className='digital-advantages__animation-container__steps__container__circle-container'>
                <div ref={circle1} className='digital-advantages__animation-container__steps__container__circle-container__circle digital-advantages__animation-container__steps__container__circle-container__circle--animation'></div>
                <img className='digital-advantages__animation-container__steps__container__circle-container__check' src="/img/check-white.svg" alt="check-white" />
              </div>
              <div className='digital-advantages__animation-container__steps__container__vertical-line'>
                <div ref={line1} className='digital-advantages__animation-container__steps__container__vertical-line__line digital-advantages__animation-container__steps__container__vertical-line__line--animation digital-advantages__animation-container__steps__container__vertical-line__line--1'></div>
              </div>
              <div className='digital-advantages__animation-container__steps__container__circle-container'>
                <div ref={circle2} className='digital-advantages__animation-container__steps__container__circle-container__circle digital-advantages__animation-container__steps__container__circle-container__circle--animation digital-advantages__animation-container__steps__container__circle-container__circle--1'></div>
                <img className='digital-advantages__animation-container__steps__container__circle-container__check' src="/img/check-white.svg" alt="check-white" />
              </div>
              <div className='digital-advantages__animation-container__steps__container__vertical-line'>
                <div ref={line2} className='digital-advantages__animation-container__steps__container__vertical-line__line digital-advantages__animation-container__steps__container__vertical-line__line--animation digital-advantages__animation-container__steps__container__vertical-line__line--2'></div>
              </div>
              <div className='digital-advantages__animation-container__steps__container__circle-container'>
                <div ref={circle3} className='digital-advantages__animation-container__steps__container__circle-container__circle digital-advantages__animation-container__steps__container__circle-container__circle--animation digital-advantages__animation-container__steps__container__circle-container__circle--2'></div>
                <img className='digital-advantages__animation-container__steps__container__circle-container__check' src="/img/check-white.svg" alt="check-white" />
              </div>
              <div className='digital-advantages__animation-container__steps__container__vertical-line'>
                <div ref={line3} className='digital-advantages__animation-container__steps__container__vertical-line__line digital-advantages__animation-container__steps__container__vertical-line__line--animation digital-advantages__animation-container__steps__container__vertical-line__line--3'></div>
              </div>
              <div className='digital-advantages__animation-container__steps__container__circle-container'>
                <div ref={circle4} className='digital-advantages__animation-container__steps__container__circle-container__circle digital-advantages__animation-container__steps__container__circle-container__circle--animation digital-advantages__animation-container__steps__container__circle-container__circle--3'></div>
                <img className='digital-advantages__animation-container__steps__container__circle-container__check' src="/img/check-white.svg" alt="check-white" />
              </div>
              <div className='digital-advantages__animation-container__steps__container__vertical-line'>
                <div ref={line4} className='digital-advantages__animation-container__steps__container__vertical-line__line digital-advantages__animation-container__steps__container__vertical-line__line--animation digital-advantages__animation-container__steps__container__vertical-line__line--4'></div>
              </div>
              <div className='digital-advantages__animation-container__steps__container__circle-container'>
                <div ref={circle5} className='digital-advantages__animation-container__steps__container__circle-container__circle digital-advantages__animation-container__steps__container__circle-container__circle--animation digital-advantages__animation-container__steps__container__circle-container__circle--4'></div>
                <img className='digital-advantages__animation-container__steps__container__circle-container__check' src="/img/check-white.svg" alt="check-white" />
              </div>
              {/* <div className='digital-advantages__animation-container__steps__container__vertical-line'>
              <div ref={line5} className='digital-advantages__animation-container__steps__container__vertical-line__line digital-advantages__animation-container__steps__container__vertical-line__line--animation digital-advantages__animation-container__steps__container__vertical-line__line--5'></div>
            </div>
            <div className='digital-advantages__animation-container__steps__container__circle-container'>
              <div ref={circle6} className='digital-advantages__animation-container__steps__container__circle-container__circle digital-advantages__animation-container__steps__container__circle-container__circle--animation digital-advantages__animation-container__steps__container__circle-container__circle--5'></div>
              <img className='digital-advantages__animation-container__steps__container__circle-container__check' src="/img/check-white.svg" alt="check-white" />
            </div>
            <div className='digital-advantages__animation-container__steps__container__vertical-line'>
              <div ref={line6} className='digital-advantages__animation-container__steps__container__vertical-line__line digital-advantages__animation-container__steps__container__vertical-line__line--animation digital-advantages__animation-container__steps__container__vertical-line__line--6'></div>
            </div>
            <div className='digital-advantages__animation-container__steps__container__circle-container'>
              <div ref={circle7} className='digital-advantages__animation-container__steps__container__circle-container__circle digital-advantages__animation-container__steps__container__circle-container__circle--animation digital-advantages__animation-container__steps__container__circle-container__circle--6'></div>
              <img className='digital-advantages__animation-container__steps__container__circle-container__check' src="/img/check-white.svg" alt="check-white" />
            </div> */}
            </div>
            <div className='digital-advantages__animation-container__steps__labels-container'>
              <span>Folio asignado</span>
              <span>Validación en progreso</span>
              <span>Validado</span>
              {/* <span>No validado</span>
            <span>Cancelación en progreso</span>
            <span>Cancelado</span> */}
              <span>Inspeccionando</span>
              <span>Concluido</span>
            </div>
          </div>
        </div>
      </div>
      <Button
        text={'Cotiza gratis'}
        onClick={() => { }}
        type={'black'}
        anchor={true}
        href="mailto:asesoria@cecsatrade.mx"
        target="_blank"
        size='big'
        className='constancy__header-button'
      />
    </section>
  )
}

export default DigitalAdvantages