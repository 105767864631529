/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { JsonLd, } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const NoticeOfConfidentiality: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'Aviso de confidencialidad';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "https://www.cecsatrade.mx/notice_of_confidentiality",
            "url": "https://www.cecsatrade.mx/notice_of_confidentiality",
            "name": "Aviso de confidencialidad",
            "description": "Aviso de confidencialidad.",
            "isPartOf": {
              "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
            },
            "datePublished": "2023-01-31T08:08:40-06:00",
            "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": {
              "@id": "https://www.cecsatrade.mx/img/favicon.svg"
            }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="Quejas y apelaciones: CECSA ENTERPRISE SOLUTIONS SC pone a disposición de sus clientes y partes interesadas el siguiente procedimiento para Quejas y Apelaciones de acuerdo al Sistema de Gestión de Calidad y a la NMX-EC-17020-IMNC-2014, por lo que te pedimos descargar el procedimiento y llenar el formato correspondiente. Una vez terminados, enviar al correo contacto@cecsatrade.mx para su posterior seguimiento por parte de CECSA
              CECSA cuenta con un periodo de 10 días hábiles para enviar una respuesta atendiendo a la parte interesada."
        />
      </Helmet>
      <section className='norm-info'>
        <div className='sub-header'>
          <div className='sub-header__spacer'></div>
          <div className='sub-header__spacer'></div>
          {/* <a className='sub-header__button' href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a> */}
          <a
            className='sub-header__email'
            href="mailto:asesoria@cecsatrade.mx"
            target="_blank">
            <img loading="lazy" src="/img/mail.svg" alt="Mail" />
            |
            Mail de CECSA
          </a>
        </div>
        <h1 className='norm-info__title'>Aviso de confidencialidad</h1>
        <div className="notice_of_confidentiality norm-info__image-container__text">
          <br />
          <p itemScope itemType="https://schema.org/Organization" className="notice_of_confidentiality__paragraph">
            <b itemProp="brand">CECSA ENTERPRISE SOLUTIONS SC</b>{' '}con domicilio en{' '}
            <b itemProp="address">Av. Cruz del Sur 3195, tercer piso, colonia Loma Bonita Ejidal, Zapopan, Jalisco, México</b> Es el responsable del uso y protección de sus datos personales, y al respecto le informa lo siguiente:
          </p>
          <p className="notice_of_confidentiality__paragraph">
            Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:
          </p>
          <ul className="notice_of_confidentiality__list">
            <li>&#8226; Informar a la autoridad sobre el resultado de la inspección del etiquetado de sus productos, cuando sea requerido por ellos o para realizar la importación de productos que requieran ser comercializados en territorio nacional.</li>
            <li>&#8226; Procesos de facturación</li>
          </ul>
          <p className="notice_of_confidentiality__paragraph">
            De manera adicional, utilizaremos su información personal para las siguientes finalidades que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención:
          </p>
          <ul className="notice_of_confidentiality__list">
            <li>&#8226; Envío de encuestas de satisfacción del servicio</li>
            <li>&#8226; Tratamiento de sus quejas y reclamaciones</li>
          </ul>
          <p className="notice_of_confidentiality__paragraph">
            Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos datos personales de identificación, contacto, laborales y las relacionadas a su producto y su información que se pretende verificar.
          </p>
          <p className="notice_of_confidentiality__paragraph">
            La siguiente información siempre será protegida y mantenida en confidencialidad
          </p>
          <ul className="notice_of_confidentiality__list">
            <li>&#8226; Origen étnico o racial</li>
            <li>&#8226; Características físicas, morales o emocionales</li>
            <li>&#8226; Vida afectiva o familiar</li>
            <li>&#8226; Domicilio particular</li>
            <li>&#8226; Número telefónico y correo electrónico particulares</li>
            <li>&#8226; Patrimonio</li>
            <li>&#8226; Ideología opinión política, afiliación sindical y creencia o convicción religiosa o filosófica.</li>
            <li>&#8226; Estado de salud física y mental</li>
            <li>&#8226; Historial medico</li>
            <li>&#8226; Preferencia sexual y otras análogas que afecten la intimidad y que puedan dar origen a discriminación o que su comunicación o entrega a terceros conlleve un riesgo para su titular.</li>
            <li>&#8226; Así como aquella considerada como confidencial por disposición legal expresa y la entregada con tal carácter por sus particulares siempre que se precisen lo medios en que se contiene y no se lesionen derechos de terceros o se contravengan disposiciones del orden público.</li>
          </ul>
          <p className="notice_of_confidentiality__paragraph">
            Le informamos que sus datos personales son compartidos dentro y fuera del país con las siguientes organizaciones y autoridades distintas a nosotros, para los siguientes fines:
          </p>
          <table className="notice_of_confidentiality__table">
            <tr>
              <th>Destinatario de los datos personales e información</th>
              <th>País</th>
              <th>Finalidad</th>
            </tr>
            <tr>
              <td>Secretaria de Economía</td>
              <td>México</td>
              <td>Para el cumplimiento de las obligaciones citadas en las normas acreditadas, en las leyes y reglamentos aplicables.</td>
            </tr>
            <tr>
              <td>Entidad Mexicana de Acreditación</td>
              <td>México</td>
              <td>Para presentar en tiempo y forma cualquier información que se solicite para confirmar que se realizaron las actividades de evaluación de la conformidad.</td>
            </tr>
          </table>
          <p className="notice_of_confidentiality__paragraph">
            Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada conforme a los principios, deberes y obligaciones previstas en la normativa (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.
          </p>
          <p className="notice_of_confidentiality__paragraph">
            El procedimiento y requisitos para el ejercicio de estos derechos es el siguiente:
          </p>
          <ol className="notice_of_confidentiality__list">
            <li>&#8226; Documentar y enviar los derechos que requiere ejercer por correo electrónico a: contacto@cecsatrade.mx</li>
            <li>&#8226; El gerente técnico de CECSA estudia su requerimiento </li>
            <li>&#8226; En caso de que no exista algún procedimiento legal o reglamentario en curso o la solicitud se encuentre procedente, se le informará por correo electrónico</li>
            <li>&#8226; A partir de la fecha de recepción no transcurrirán más de 10 días hábiles para proceder con su solicitud. En caso de no procedencia, también se lo informaremos en ese plazo</li>
          </ol>
          <p className="notice_of_confidentiality__paragraph">
            Los datos de contacto de la persona o departamento que dará trámite a las solicitudes para el ejercicio de los derechos ARCO, así como atender cualquier duda que pudiera tener respecto al tratamiento de su información es:
          </p>
          <p itemScope itemType="https://schema.org/Person" itemProp="funder" className="notice_of_confidentiality__paragraph">
            Gerente Técnico: <u>César Iván Castro Ramírez</u>
          </p>
          <p itemScope itemType="https://schema.org/Organization" itemProp="telephone" className="notice_of_confidentiality__paragraph">
            Teléfono: <u>(348) 105 1510</u>
          </p>
          <p itemScope itemType="https://schema.org/Organization" itemProp="email" className="notice_of_confidentiality__paragraph">
            Correo electrónico: <u>contacto@cecsatrade.mx</u>
          </p>
          <p className="notice_of_confidentiality__paragraph">
            Con objeto de que pueda limitar el uso y divulgación de su información personal, le sugerimos:
          </p>
          <p className="notice_of_confidentiality__paragraph">
            Su inscripción en el Registro Público para Evitar Publicidad (REPEP), que está a cargo de la Procuraduría Federal del Consumidor, con la finalidad de que sus datos personales no sean utilizados para recibir publicidad o promociones de empresas de bienes o servicios. Para mayor información sobre este registro, usted puede consultar el portal de Internet de PROFECO.
          </p>
          <p className="notice_of_confidentiality__paragraph">
            Le informamos que en nuestra página de Internet utilizamos cookies y otras tecnologías a través de las cuales es posible monitorear su comportamiento como usuario de Internet, brindarle un mejor servicio y experiencia de usuario al navegar en nuestra página, así como ofrecerle nuevos productos y servicios basados en sus preferencias.  Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes: horario de navegación, tiempo de navegación en nuestra página de Internet, secciones consultadas, y páginas de Internet accedidas previo a la nuestra.
          </p>
          <p className="notice_of_confidentiality__paragraph">
            Estas tecnologías podrán deshabilitarse siguiendo los siguientes pasos: 1. Acceder a nuestra página de Internet, sección “Términos y condiciones del sitio”, subsección “Cookies”; 2. Dar clic en la subsección “Cookies”; 3. Leer el mensaje de advertencia sobre la des habilitación de cookies, y 4. Dar clic en la leyenda de activar el mecanismo de des habilitación	 de cookies.
          </p>
          <p className="notice_of_confidentiality__paragraph">
            El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.  Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de nuestra página de internet ya citada.
          </p>
        </div>
        <br />
        <br />
        <br />
        <br />
      </section >
    </>
  )
}

export default NoticeOfConfidentiality