import React, {
  createContext,
  useState,
  ReactElement
} from 'react';

interface DataContext {
  modalOpen: boolean,
  setModalOpen: (_value: boolean) => void
}

export const AppContext = createContext<DataContext>({
  modalOpen: false,
  setModalOpen: () => { }
});

interface Props {
  children: ReactElement
}

export const AppProvider: React.FC<Props> = (props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <AppContext.Provider value={
      {
        modalOpen,
        setModalOpen
      }
    }>
      {props.children}
    </AppContext.Provider>
  );
};
