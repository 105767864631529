/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { BenefictsAndWhoDifferent, ExcusiveAttention, JsonLd, NormInfo, References, ServiceSteps, ServicesToOneClick } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom051: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-051-SCFI/SSA1-2010';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org", "@type": "WebPage", "@id":
              "https://www.cecsatrade.mx/nom-051-scfi-ssa1-2010", "url":
              "https://www.cecsatrade.mx/nom-051-scfi-ssa1-2010", "name":
              "NOM-051-SCFI/SSA1-2010", "description": `La NOM-051 regula el etiquetado de alimentos y bebidas no alcohólicas asegurando que la información nutricional del producto sea clara, precisa y verídica para proteger al consumidor.`, "isPartOf": {
                "@id":
                  "https://www.cecsatrade.mx/#/schema/WebSite/1"
              }, "datePublished":
              "2023-01-31T08:08:40-06:00", "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": { "@id": "https://www.cecsatrade.mx/assets/051.svg" }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="La NOM-051 regula el etiquetado de alimentos y bebidas no alcohólicas asegurando que la información nutricional del producto sea clara, precisa y verídica para proteger al consumidor."
        />
      </Helmet>
      <section className='norm-info'>
        <div className='sub-header'>
          <div className='sub-header__spacer'></div>
          <div className='sub-header__spacer'></div>
          {/* <a className='sub-header__button' href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a> */}
          <a
            className='sub-header__email'
            href="mailto:asesoria@cecsatrade.mx"
            target="_blank">
            <img loading="lazy" src="/img/mail.svg" alt="Mail" />
            |
            Mail de CECSA
          </a>
        </div>
        <h1 className='norm-info__title'>NOM-051-SCFI/SSA1-2010 ALIMENTOS Y BEBIDAS NO ALCOHÓLICAS</h1>
        <div className='norm-info__header norm-info__header--051'>
          <button onClick={() => {
            const element = document.getElementById("benefits");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
          }}>Beneficios</button><a href='https://wa.me/message/YZR3TYWZRARDH1' target='_blank'>Quiero cumplir con la NOM</a>
          <button onClick={() => {
            const element = document.getElementById("preguntas-frecuentes");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
            }
          }}>Preguntas frecuentes</button>
        </div>

        <div className='norm-info__image-container'>
          {/* <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-003-asset.svg" alt="NOM 003 Logo" /> */}
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Sé el primero en saber...</h2>
            <br />
            <p>
              La <b>norma de alimentos de bebidas no alcohólicas</b> preenvasados tiene como objeto dar a conocer la <b>informaciópn comercial</b> y sanitaria necesaria para su correcto etiquetado, ya sea de fabricación nacional o extranjera.
              <br />
              <br />
              Esta <b>Norma Oficial Mexicana</b> es aplicable a todos los alimentos y bebidas no alcohólicas preenvasados destinadas al <b>consumidor</b> dentro del país.
            </p>
          </div>
        </div>
        <div className='norm-info__image-container norm-info__image-container--black'>
          <div className='norm-info__image-container__img-container'>
            <img className='norm-info__image-container__img-container--cookies' loading="lazy" src="/img/cookies.svg" alt="cookies" />
          </div>
          <div className='norm-info__image-container__text norm-info__image-container__text--center norm-info__image-container__text--small-margin'>
            <p>
              La <b>NOM-051</b> regula el <b>etiquetado de alimentos y bebidas no alcohólicas</b> asegurando que la <b>información nutricional del producto</b> sea clara, precisa y verídica para proteger al consumidor.
            </p>
            <button onClick={() => {
              const element = document.getElementById("benefits");

              if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
              }
            }}>Beneficios</button>
          </div>
        </div>
        <br />
        <br />
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Entrada en vigor</h2>
            <br />
            <p>
              La <b>NOM-051-SCFI/SSA1-2010, Especificaciones generales de etiquetado para alimentos y bebidas no alcohólicas preenvasados-información comercial y sanitaria,</b> entró en vigor el 27 de marzo del 2020, pero fué publicada en el <u>Diario de la Federeación</u> el 01 de octubre del 2020.
            </p>
            <br />
            <a className='norm-info__image-container__anchorage' href="https://www.dof.gob.mx/normasOficiales/4010/seeco11_C/seeco11_C.htm" target='_blank'>Más información de esta NOM</a>
          </div>
        </div>
        <div className='norm-info__image-container  norm-info__image-container--blue'>
          <img className='norm-info__image-container__image-background-1' loading="lazy" src="/img/background-blue-1.svg" alt="productos" />
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <br />
            <br />
            <h2>La norma no se aplica a:</h2>
            <br />
            <p>
              El <b>nuevo etiquetado</b> brinda al consumidor información clara y visible sobre el producto que está comprando. Así, el consumidor tendrá la libertad y responsabilidad de decidir sobre su consumo. <br />Recuerda que cierto productos <b>no pueden entrar</b> en esta NOM.
            </p>
            <br />
            <div className='norm-info__image-container__items-container'>
              <div className='norm-info__image-container__items-container__item'>
                <img loading="lazy" src="/img/no-acoholicas.svg" alt="no alcohólicas" />
                <span>a) Alimentos y bebidas no alcohólicas preenvasadas sujetas a NOMS específicas que excluyan su cumplimiento</span>
              </div>
              <div className='norm-info__image-container__items-container__item'>
                <img loading="lazy" src="/img/granel.svg" alt="granel" />
                <span>b) Productos a granel</span>
              </div>
              <div className='norm-info__image-container__items-container__item'>
                <img loading="lazy" src="/img/preenvasados.svg" alt="preenvasados" />
                <span>c) Los alimentos y bebidas no alcohólicas envasados en punto de venta</span>
              </div>
              <div className='norm-info__image-container__items-container__item'>
                <img loading="lazy" src="/img/productos.svg" alt="productos" />
                <span>d) Los demás productos que determine la autoridad competente, conforme a sus atribuciones</span>
              </div>
            </div>
            <br />
            <br />
          </div>
          <img className='norm-info__image-container__elipse-blue-2' loading="lazy" src="/img/background-blue-2.svg" alt="productos" />
        </div>
        <ExcusiveAttention />
        <div className='norm-info__image-container norm-info__image-container--black norm-info__image-container--black-051'>
          {/* <img className='norm-info__image-container__image-top' loading="lazy" src="/img/chorro.png" alt="bebida chorro" /> */}
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>CONTENIDO DE LA ETIQUETA</h2>
            <br />
            <p>
              La etiqueta debe cumplir con ciertas características para cumplir con la Norma Oficial Mexicana.
            </p>
            <br />
            <div className='norm-info__image-container__text__item-data'>
              <span>Etiquetado frontal</span>
              <p>Debe contener la denominación o nombre del producto, marca, contenido neto, información nutrimental complementaria (octágonos negros) y leyendas precautorias. Nota: También incluye a los productos imitación, también se deberá colocar la leyenda en la parte frontal.</p>
            </div>
            <div className='norm-info__image-container__text__item-data'>
              <span>Etiquetado en superficie de información</span>
              <p>Debe contener la información que complementa la que debe de ir en el etiquetado frontal como: ingredientes, declaración nutrimental, lote, fecha de caducidad o consumo preferente, leyendas de conservación, país de origen, etc.</p>
            </div>
            <br />
            <h2>COLOCACIÓN CORRECTA DE LA ETIQUETA</h2>
            <br />
            <p>Existen dos formas para colocar la información correspondiente a los productos comercializados en México.</p>
            <br />
            <div className='norm-info__image-container__data-container'>
              <div className='norm-info__image-container__data-container__data'>
                <span>1. Es de origen, aplica a todo producto de fabricación nacional, de forma ya implícita en el empaque, bolsa, envase, caja, blíster, entre otros.</span>
                <span>2. Mediante etiquetado superficial permanente, aplica a todo producto importado, debe venir la etiqueta adherida en una etiqueta superficial sobre la información de origen o complementando la misma.</span>
              </div>
              <div className='norm-info__image-container__data-container__image'>
                <img loading="lazy" src="/img/bebidas.svg" alt="bebida topo chico" />
              </div>
            </div>
            <br />
            <p>
              La <b>información comercial</b> mediante etiquetado superficial aplica a todo producto importado, es decir, que la información debe de venir adherida en una etiqueta superficial sobre la información de origen o complementando la misma. Hay dos tipos de etiquetado que se debe adicionar en el etiquetado superficial, el etiquetado frontal y el de información adicional. Si eres comerciante nacional o importador de <b>alimentos y bebidas no alcohólicas preenvasados</b>, te invitamos a conocer más sobre esta norma. </p>
            <br />
            <img loading="lazy" src="/img/sellos.svg" alt="sellos" style={{ width: '100%' }} />
            <img className='norm-info__image-container norm-info__image-container--black-051-ellipse' loading="lazy" src="/img/ellipse-1.svg" alt="ellipse" />
          </div>
        </div>
        <BenefictsAndWhoDifferent title={'CERTIFICA TUS ALIMENTOS Y BEBIDAS NO ALCOHÓLICAS'} text={'Contactanos, nosotros te apoyamos en cada paso que debes realizar para cumplir conforme lo establecido de manera nacional en materia de comercial dentro de la industria.'} />
        <References
          items={[
            (
              <li>
                <b>NOM-002-SCFI-1993</b>, Productos preenvasados-Contenido neto-Tolerancias y métodos de verificación.
              </li>
            ),
            (
              <li>
                <b>NOM-008-SCFI-2002</b>, Sistema General de Unidades de Medida.
              </li>
            ),
            (
              <li>
                <b>NOM-030-SCFI-2006</b>, Información comercial-Declaración de cantidad en la etiqueta-especificaciones.
              </li>
            ),
            (
              <li>
                <b>NOM-043-SSA2-2005</b>, Servicios básicos de salud. Promoción y educación para la salud en materia alimentaria. Criterios para brindar orientación.
              </li>
            ),
            (
              <li>
                <b>NOM-086-SSA1-1994</b>, Bienes y servicios - Alimentos y bebidas no alcohólicas con modificaciones en su composición. Especificaciones nutrimentales.
              </li>
            )
          ]}
        />
        <NormInfo
          DOFDate='27/03/2020'
          VigorDate='01/10/2020'
          responsible='SECRETARIA DE ECONOMIA Y SECRETARIA DE SALUD'
        />
        <div className='norm-info__questions' id='preguntas-frecuentes'>
          <h2 className="norm-info__container__sub-title">Preguntas frecuentes</h2>
          <ul className="accordion norm-info__container__not-transform-ul" id="accordion">
            <li className="accordion-item">
              <div id="heading1">
                <button className="accordion-button norm-info__container__accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                  ¿Qué es la NOM-051-SCFI/SSA1-2010?
                </button>
              </div>
              <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordion">
                <div className="accordion-body">
                  La NOM-051-SCFI/SSA1-2010 referente a alimentos y bebiddas no alcohólicas preenvasadas obliga a las empresas de esta gama a incluir un nuevo sistema de etiquetado frontal en la esquina superior derecha, exhibiendo sellos de advertencia en octágonos negros y leyendas precautorias en torno a la información nutrimental de estos productos.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                  ¿Quiénes regulan la NOM-051-SCFI/SSA1-2010?
                </button>
              </div>
              <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordion">
                <div className="accordion-body">
                  ECOFEPRIS y PROFECO mantienen acciones de vigilancia a productos para asegurar la correcta implementación de esta norma, la cual coadyuva a la protección de la salud de las personas.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                  ¿Cómo puedo cumplir con la NOM-051-SCFI/SSA1-2010?
                </button>
              </div>
              <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Para realizar el cumplimiento de esta norma te invitamos a acercarte a una unidad de inspección acreditada, con CECSA te brindamos el apoyo de revisar la información comercial de tu mercancía para proporcionarte el etiquetado correcto con base a esta normativa y asi al ser verificado el producto, se podrán imprimir las etiquetas y reetiquetar en el caso de que lleve algún sello o leyenda emtiendo como resultado final  un dictamen de cumplimiento para que tu mercancía teniendo en cuenta las fechas que sean indicadas  para  no tener problema alguno.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                  ¿Cuáles son los productos que entran con base a la NOM-051-SCFI/SSA1-2010?
                </button>
              </div>
              <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Esta Norma tiene por objeto dar a conocer la información comercial y sanitaria necesaria para su correcto etiquetado, ya sea de fabricación nacional o extranjera. Algunas excepciones para esta norma son los siguientes productos:
                  <br />a) Alimentos y bebidas no alcohólicas preenvasadas sujetas a NOMS  especificas que excluyan su cumplumiento.<br />b) Productos a granel<br />c) Los alimentos y bebidas no alcohólicas envasados en punto de venta.<br />d) Los demás productos que determine la autoridad competente, conforme a sus atribuciones.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                  ¿Que prohibe la NOM-051-SCFI/SSA1-2010?
                </button>
              </div>
              <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Productos que presenten irregularidades en su empaque, entre las que destacan declarar la lista de ingredientes y sellos de manera conjunta, utilizar imágenes animadas o elementos interactivos en productos con sellos, colocación de sellos en ubicaciones irregulares y contener nutrientes críticos añadidos (azúcares, grasas o sodio), sin sellos de advertencia.  Productos  que no cumplan con las acciones  para garantizar la transparencia, informar a consumidores sobre los productos que están adquiriendo y proteger la salud de la población.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                  ¿Cuáles son las consecuencias de no acreditar la NOM-051-SCFI/SSA1-2010?
                </button>
              </div>
              <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Las multas a los productos pueden incluir su retiro de ventas “Iinmobilización de sus productos”  si no cumplen con los requisitos del empaque, y en algunos casos, sanciones a las tiendas que no cumplan con la vigilancia de la NOM-051, pues no estarían dando la información adecuada al consumidor.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                  ¿Cómo puedo demostrar el cumplimiento de la NOM-051-SCFI/SSA1-2010?
                </button>
              </div>
              <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordion">
                <div className="accordion-body">
                  <b>Contactando a CECSA</b>, ya que nuestros grupo de expertos en la materia te dirán cuales son los requisitos que debe de llevar tu producto en la etiqueta, de esa manera, evitarás demoras, pérdidas de tiempo, pagos de almacenaje y re etiquetado.
                  <br /><br />CECSA revisará la etiqueta, y procederá a hacerte observaciones para que se realicen modificaciones. O en su caso, si la etiqueta se encuentra correcta procederá a emitir la Constancia de Inspección. <br /><br />Lo que deberás hacer es presentar la Constancia de Inspección en la aduana junto con el producto. Ambos deben de coincidir, de esa manera, podrás cumplir con las regulaciones y restricciones no arancelarias de etiquetado. <br /><br />Recuerda que la vigencia de la Constancia de Inspección es de manera indefinida. <br />La única manera en que la constancia pierda su vigencia es que: <br />Se actualice la Norma NOM-051-SCFI/SSA1-2010 <br />Que el producto cambie el etiquetado <br />Que se agregue una diferente formulación al producto <br />Que cambie la razón social de importador <br /><br />Y en general que cambie cualquier aspecto de la presentación del producto.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                  ¿Qué son los sellos de advertencia?
                </button>
              </div>
              <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Cada sello representa ingredientes que contiene el producto, por lo cual se colocan los sellos acordes si excede alguno de los límites de sodio, azúcares, grasas trans, grasas saturadas y edulcorantes. Es importante notar que hay productos que no tienen sellos, pero hay que dejar de lado los ingredientes que estos contienen. <br /><br />Otro dato importante es que, de acuerdo con el gramaje de los productos, es que los sellos consideran los 100 gramos, entonces hay productos que pueden ser más dañinos cuando exceden de esto. <br /><br />Los sellos pueden ayudar a: <br />Tomar una decisión de compra más informada. <br />Informar de manera fácil, rápida y cierta sobre los nutrientes. <br />Comparar el contenido específico con productos similares. <br />Advierte de ingredientes que son malos para la salud.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                  ¿Cuáles han sido los nuevos cambios o modificaciones que ha tenido la NOM 051-SCFI/SSA1-2010?
                </button>
              </div>
              <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordion">
                <div className="accordion-body">
                  La primera fase entró en vigor en octubre de 2020, con una prórroga de dos meses. Estableció cambios obligatorios para la implementación de sellos de advertencia en alimentos preenvasados con exceso de ingredientes críticos. También estableció la obligatoriedad de leyendas sobre el contenido en exceso de estos componentes. <br /><br />La segunda fase entró en vigor el pasado 1 de octubre y estará vigente hasta el 30 de septiembre de 2025. Comprende un criterio más estricto para la evaluación de los azúcares y grasas añadidas a los productos alimenticios preenvasados. <br /><br />La tercera fase entrará en vigor el 1 de octubre de 2025 y contempla la integración de una etiqueta definitiva en la que se aplicará el perfil de forma integra de los productos alimenticios ultraprocesados. <br /><br />Es importante recordar que en cada fase los criterios para la evaluación los nutrientes críticos han aumentado en severidad.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                  ¿Qué hago si detienen mi producto en aduana?
                </button>
              </div>
              <div id="collapse10" className="accordion-collapse collapse" aria-labelledby="heading10" data-bs-parent="#accordion">
                <div className="accordion-body">
                  En primer lugar, deberás de solicitar la fracción arancelaria del producto. Una vez que tengas la fracción arancelaria y corrobores que le solicitan el cumplimiento de la NOM-051-SCFI/SSA1-2010, deberás de contactar a una Unidad de Inspección para obtener una Constancia de Inspección. <br /><br />Debido a que el producto no puede etiquetarse en México, deberás de etiquetar en la aduana o regresar el producto con tu proveedor para que sea etiquetado. <br />Te recomendamos analizar los costos de cada situación y evaluarlos. <br /><br />Una vez que nos contactes, te brindaremos la asesoría y apoyo para que puedas liberar tu producto de la aduana. El proceso será rápido, cada minuto cuenta, por lo que te dejamos nuestro correo (contacto@cecsatrade.mx) y número de contacto (56 5019 0728)
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading11">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                  ¿Cómo importar alimentos con base a la NOM-051-SCFI/SSA1-2010?
                </button>
              </div>
              <div id="collapse11" className="accordion-collapse collapse" aria-labelledby="heading11" data-bs-parent="#accordion">
                <div className="accordion-body">
                  De acuerdo con el ACUERDO por el que la Secretaría de Economía emite Reglas y criterios de carácter general en materia de comercio exterior este tipo de productos solamente se podrá importar bajo el amparo de una constancia de inspección o constancia de conformidad emitida por una unidad de inspección acreditada y aprobada en los términos de la Ley de la Infraestructura de la Calidad. <br /><br />Para poder importar un producto con la <b>NOM-051-SCFI/SSA1-2010</b> deberás etiquetar previo al despacho aduanero, es decir, en origen, con tu proveedor o en la misma aduana.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading12">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                  ¿Cómo afecta la NOM-051 a los productos importados?
                </button>
              </div>
              <div id="collapse12" className="accordion-collapse collapse" aria-labelledby="heading12" data-bs-parent="#accordion">
                <div className="accordion-body">
                  El Servicio Nacional de Información de Comercio Exterior, SNICE, publicó el acuerdo por el que la Secretaría de Economía emite reglas y criterios de carácter general en materia de comercio exterior, como complemento a los criterios de interpretación de las NOMs de etiquetado sanitario y comercial publicados en 2020, donde quedan excluidas las importaciones de materias primas, insumos o productos a granel no destinados al consumidor final. <br /><br />Es decir, se trata principalmente de mercancías utilizadas como parte de un proceso productivo o de transformación. <br /><br />Asimismo, dentro de los criterios también se incluye a aquellos productos envasados que no se presentan en el recipiente definitivo y final que aparecerá frente a los ojos del consumidor para ser adquirido como producto en punto de venta, y donde tampoco deberá exigirse la demostración del cumplimiento de las NOMs comerciales ante la UIA ( Unidad de Inspección Acreditada).
                </div>
              </div>
            </li>
          </ul>
        </div>
        <img className='norm-info__ellipse' src="/img/ellipse-blue.svg" alt="ellipse" />
        <ServicesToOneClick />
        <ServiceSteps
          withNewsLetter={true}
        />
      </section >
    </>
  )
}

export default Nom051