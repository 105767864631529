/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { BenefictsAndWhoDifferent, ExcusiveAttention, JsonLd, NormInfo, References, ServiceSteps, ServicesToOneClick } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom142: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-187-SSA1/SCFI-2002 - Tortillas y masa';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org", "@type": "WebPage", "@id":
              "https://www.cecsatrade.mx/nom-187-ssa1-scfi-2002", "url":
              "https://www.cecsatrade.mx/nom-187-ssa1-scfi-2002", "name":
              "NOM-187-SSA1/SCFI-2002", "description": `La NOM-187-SSA1/SCFI-2002 establece los requisitos sanitarios y comerciales para masas, tortillas y tostadas en México, garantizando la seguridad alimentaria y la correcta información al consumidor, regulando la calidad e inocuidad de estos productos básicos.`, "isPartOf": {
                "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
              }, "datePublished":
              "2023-01-31T08:08:40-06:00", "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": { "@id": "https://www.cecsatrade.mx/assets/187.svg" }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="La NOM-187-SSA1/SCFI-2002 establece los requisitos sanitarios y comerciales para masas, tortillas y tostadas en México, garantizando la seguridad alimentaria y la correcta información al consumidor, regulando la calidad e inocuidad de estos productos básicos."
        />
      </Helmet>
      <section className='norm-info'>
        <div className='sub-header'>
          <div className='sub-header__spacer'></div>
          <div className='sub-header__spacer'></div>
          {/* <a className='sub-header__button' href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a> */}
          <a
            className='sub-header__email'
            href="mailto:asesoria@cecsatrade.mx"
            target="_blank">
            <img loading="lazy" src="/img/mail.svg" alt="Mail" />
            |
            Mail de CECSA
          </a>
        </div>
        <h1 className='norm-info__title'>NOM-187-SSA1/SCFI-2002 MASA, TORTILLAS Y TOSTADAS</h1>
        <div className='norm-info__header norm-info__header--187'>
          <button onClick={() => {
            const element = document.getElementById("benefits");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
          }}>Beneficios</button><a href='https://wa.me/message/YZR3TYWZRARDH1' target='_blank'>Quiero cumplir con la NOM</a>
          <button onClick={() => {
            const element = document.getElementById("preguntas-frecuentes");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
            }
          }}>Preguntas frecuentes</button>
        </div>
        <div className='norm-info__image-container'>
          {/* <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-003-asset.svg" alt="NOM 003 Logo" /> */}
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Sé el primero en saber...</h2>
            <br />
            <p>
              La <b>NOM-187</b> para masas, tortillas, tostadas y harinas preparadas establece las especificaciones sanitarias y la información comercial necesaria para su correcto etiquetado, garantizando la protección y seguridad del consumidor
              <br />
              <br />
              Esta <b>Norma Oficial Mexicana</b> establece las disposiciones para garantizar información precisa y eficaz al consumidor, definiendo la clasificación, ingredientes y especificaciones que deben cumplir los productos derivados del maíz de esta NOM.
            </p>
          </div>
        </div>
        <div className='norm-info__image-container norm-info__image-container--black'>
          <div className='norm-info__image-container__img-container'>
            <img className='norm-info__image-container__img-container__image' loading="lazy" src="/img/harinas.png" alt="harinas" />
          </div>
          <div className='norm-info__image-container__text norm-info__image-container__text--center norm-info__image-container__text--small-margin'>
            <p style={{ textAlign: 'left' }}>
              La <b>NOM-187-SSA1/SCFI-2002</b> establece los requisitos sanitarios y comerciales para masas, tortillas y tostadas en México, garantizando la seguridad alimentaria y la correcta información al consumidor, regulando la calidad e inocuidad de estos productos básicos.
            </p>
            <button onClick={() => {
              const element = document.getElementById("benefits");

              if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
              }
            }}>Beneficios</button>
          </div>
        </div>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Entrada en vigor</h2>
            <br />
            <p>
              La <b>NOM-187-SSA1/SCFI-2002</b> establece las <b>especificaciones sanitarias, información comercial y métodos de prueba para la masa, tortillas, tostadas, y las harinas preparadas</b>, así como para los establecimientos que las procesan. Fue publicada en el Diario Oficial de la Federación el 18 de agosto de 2003 y entró en vigor el 14 de febrero de 2004.
            </p>
            <br />
            <a className='norm-info__image-container__anchorage' href="https://www.dof.gob.mx/nota_detalle.php?codigo=5269348&fecha=19/09/2012#gsc.tab=0" target='_blank'>Más información de esta NOM</a>
          </div>
        </div>
        <img className='norm-info__ellipse' src="/img/ellipse-black.svg" alt="ellipse" />
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Aplicación de la NOM-187</h2>
            <br />
            <p>
              <div className='norm-info__image-container__aplications-015'>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-187-1.svg" alt="Tortillas del maíz" />
                  <span><b>Tortillas del maíz</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-187-2.svg" alt="Tortillas de harina" />
                  <span><b>Tortillas de harina</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-187-3.svg" alt="Maíz" />
                  <span><b>Maíz</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-187-4.svg" alt="Botanas de maíz y trigo" />
                  <span><b>Botanas de maíz y trigo </b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-187-5.svg" alt="Tostadas" />
                  <span><b>Tostadas</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-187-6.svg" alt="Harinas nixtamalizadas de maíz" />
                  <span><b>Harinas nixtamalizadas de maíz</b></span>
                </div>
              </div>
            </p>
          </div>
        </div>
        <ExcusiveAttention />
        <div className='norm-info__image-container norm-info__image-container--blue-050'>
          <img className='norm-info__image-container__image-background-1' loading="lazy" src="/img/background-circle-blue-1.svg" alt="backghround 1" />
          <div className='norm-info__image-container__text'>
            <h2>La NOM-142-SCFI/SSA1-2014 no aplica a:</h2>
            <br />
            <p>La presente <b>Norma Oficial Mexicana</b> no es aplicable a lo siguiente:</p>
            <br />
            <div className='norm-info__image-container__text__data-item-container-004'>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-187-1.svg" alt="No derivados del maíz" />
                <span><b>No derivados del maíz</b></span>
              </div>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-187-2.svg" alt="Harina de avena" />
                <span><b>Harina de avena</b></span>
              </div>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-187-3.svg" alt="Galletas de respostería" />
                <span><b>Galletas de respostería</b></span>
              </div>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-187-4.svg" alt="Pasteles" />
                <span><b>Pasteles </b></span>
              </div>
            </div>
            <br />
          </div>
          <img className='norm-info__image-container__image-background-2' loading="lazy" src="/img/background-circle-blue-2.svg" alt="backghround 2" />
        </div>
        <div className='norm-info__image-container norm-info__image-container--black norm-info__image-container--margin norm-info__image-container--050' style={{ marginBottom: '30rem', overflow: 'visible', zIndex: '7' }}>
          <h2>DEFINICIONES</h2>
          <div className="norm-info__050-definitions__item">
            <span>Tortilla</span>
            <p>Producto elaborado con masa que puede ser mezclada con ingredientes opcionales, sometida a cocción.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Tostada</span>
            <p>Producto elaborado a partir de tortilla o masa, mezclada con ingredientes y sometida a procesos como horneado, freído o deshidratado, para obtener una textura rígida y crujiente.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Maíz nixtamalizado o nixtamal</span>
            <p>Maíz que ha sido sometido a una cocción parcial con agua en presencia de hidróxido de calcio (cal, óxido de calcio).</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Masa</span>
            <p>Producto obtenido de la molienda húmeda de granos de maíz nixtamalizado o de la mezcla de harina de maíz nixtamalizado, harina de trigo, harinas integrales, y agua.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Harina preparada para elaborar masa</span>
            <p>Producto resultante de la mezcla de harina de trigo, maíz nixtamalizado u otros cereales, con ingredientes opcionales y aditivos.</p>
          </div>
          <h2>CONTENIDO DE LA ETIQUETA</h2>
          <br />
          <br />
          <p>Las etiquetas deben ofrecer información clara sobre el contenido, origen y características del producto en español, con opción de incluir otros idiomas.</p>
          <br />
          <div className="norm-info__015-definitions__item norm-info__015-definitions__item--images">
            <img src="/img/187-logo-1.svg" alt="187-logo-1" />
            <img src="/img/187-logo-2.svg" alt="187-logo-2" />
            <img src="/img/187-logo-3.svg" alt="187-logo-3" />
            <img src="/img/187-logo-4.svg" alt="187-logo-4" />
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Nombre del producto</span>
            <p>Denominación genérica y describirse de forma que no induzca a error al consumidor</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Contenido neto</span>
            <p>Declarado en unidades del Sistema General de Unidades de Medida</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>País de Origen</span>
            <p>Leyendas como "Hecho en…" o "Producto de…" para identificar el país de fabricación.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Nombre y domicilio</span>
            <p>Nombre, razón social y domicilio del fabricante o importador.</p>
          </div>
          <br />
          <br />
          <h2>Información Sanitaria</h2>
          <br />
          <br />
          <div className="norm-info__050-definitions__item">
            <span>Lista de ingredientes</span>
            <p>Deben listarse en orden y encabezarse con "Ingredientes"</p>
          </div>
          <div className="norm-info__015-definitions__item norm-info__015-definitions__item--column">
            <img src="/img/tortillas-1.png" alt="tortillas" />
            <div className="norm-info__015-definitions__item__container">
              <p>
                <b>Instrucciones de uso</b>
                <br />
                Deben incluirse si el envase lo requiere, además de las condiciones de conservación
                <br />
                <br />
                <b>Fecha de caducidad</b>
                <br />
                Debe ser clara e inalterable, incluyendo la leyenda "Fecha de caducidad" o similar.
              </p>
            </div>
          </div>
          <p className='norm-info__image-container__text-center'>
            <br />
            <br />
            Los productos deben estar envasados en materiales inocuos y resistentes que no alteren sus características.
            <br />
            <br />
            Este etiquetado garantiza la seguridad del consumidor y la transparencia en la información de los productos.
            <br />
            <br />
          </p>
          <img className='norm-info__050-definitions__footer' loading="lazy" src="/img/definitions-050-footer.svg" alt="footer" />
        </div>
        <BenefictsAndWhoDifferent
          title={'CERTIFICA TUS PRODUCTOS'}
          text={'Contactanos, nosotros te apoyamos en cada paso que debes realizar para cumplir conforme lo establecido de manera nacional en materia de comercial dentro de la industria.'}
        />
        <References
          items={[
            (
              <li>
                <img src="/img/list-item-187.png" alt="juguete" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=4757142&fecha=09/07/1993#gsc.tab=0" target='_blank'>NOM-030-SCFI-1993</a>, Información comercial - Declaración de cantidad en la etiqueta - Especificaciones.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-187.png" alt="juguete" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=5531604&fecha=16/07/2018#gsc.tab=0" target='_blank'>NOM-040-SSA1-1993</a>, Bienes y servicios. Sal yodada y sal yodada fluorurada. Especificaciones sanitarias.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-187.png" alt="juguete" />
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=4867159&fecha=24/01/1996#gsc.tab=0" target='_blank'>NOM-050-SCFI-1994</a>, Información comercial. Disposiciones generales para productos.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-187.png" alt="juguete" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=4890075&fecha=26/06/1996#gsc.tab=0" target='_blank'>NOM-086-SSA1-1994</a>, Bienes y servicios. Alimentos y bebidas no alcohólicas con modificaciones en su composición. Especificaciones nutricionales.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-187.png" alt="juguete" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=4883170&fecha=16/10/1995#gsc.tab=0" target='_blank'>NOM-110-SSA1-1994</a>
                , Bienes y servicios. Preparación y dilución de muestras de alimentos para su análisis microbiológico.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-187.png" alt="juguete" />
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=4728930&fecha=15/08/1994#gsc.tab=0" target='_blank'>NOM-113-SSA1-1994</a>, Bienes y servicios. Método para la cuenta de microorganismos coliformes totales en placa.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-187.png" alt="juguete" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=4869827&fecha=23/02/1995#gsc.tab=0" target='_blank'>NOM-117-SSA1-1994</a>, Bienes y servicios. Método de prueba para la determinación de cadmio, arsénico, plomo, estaño, cobre, fierro, zinc y mercurio en alimentos, agua potable y agua purificada por espectrometría de absorción atómica.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-187.png" alt="juguete" />
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=4880184&fecha=28/08/1995#gsc.tab=0" target='_blank'>NOM-120-SSA1-1994</a>, Bienes y servicios. Prácticas de higiene y sanidad en la elaboración de alimentos y bebidas no alcohólicas y alcohólicas.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-187.png" alt="juguete" />
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=2063863&fecha=31/12/1969#gsc.tab=0" target='_blank'>NOM-127-SSA1-1994</a>, Salud ambiental, agua para uso y consumo humano - Límites permisibles de calidad y tratamientos a que debe someterse el agua para su potabilización
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-187.png" alt="juguete" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=4891221&fecha=15/08/1997#gsc.tab=0" target='_blank'>NOM-147-SSA1-1996</a>, Bienes y servicios. Cereales y sus productos. Harinas de cereales, sémolas o sémolas. Alimentos a base de cereales, de semillas comestibles, harinas, sémolas o sémolas o sus mezclas. Productos de panificación. Disposiciones y especificaciones sanitarias y nutricionales.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-187.png" alt="juguete" />
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=719385&fecha=15/10/2002#gsc.tab=0" target='_blank'>NOM-188-SSA1-2002</a>, Productos y servicios. Control de aflatoxinas en cereales para consumo humano y animal. Especificaciones sanitarias.
              </li>
            )
          ]}
        />

        <NormInfo
          DOFDate='18/08/2003'
          VigorDate='14/02/2004'
          responsible='SECRETARÍA DE SALUD Y SECRETARÍA DE ECONOMÍA'
        />
        <div className='norm-info__questions' id='preguntas-frecuentes'>
          <h2 className="norm-info__container__sub-title">Preguntas frecuentes</h2>
          <ul className="accordion norm-info__container__not-transform-ul" id="accordion">
            <li className="accordion-item">
              <div id="heading1">
                <button className="accordion-button norm-info__container__accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                  ¿Qué pasa si no cumplo con la NOM-187-SSA1/SCFI-2002?
                </button>
              </div>
              <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordion">
                <div className="accordion-body">
                  De acuerdo con el ACUERDO por el que la Secretaría de Economía emite Reglas y criterios de carácter general en materia de comercio exterior este tipo de productos solamente se podrá importar bajo el amparo de una constancia de inspección o constancia de conformidad emitida por una unidad de inspección acreditada y aprobada en los términos de la Ley de la Infraestructura de la Calidad.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                  ¿Qué prohíbe específicamente la NOM-187?
                </button>
              </div>
              <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Prohibe la producción y la importación de maíz genéticamente modificado asi como la elaboración y comercialización de tortillas con maíz genéticamente modificado por parte del Consejo Consultor de Mercados Agrícolas, encabezado por Juan Carlos Anaya.
                  <br />
                  <br />
                  Prohibir la elaboración y comercialización de tortillas con maíz genéticamente modificado.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading3">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                  ¿Cuáles son los principales requisitos de calidad que deben cumplir las tortillas según la NOM-187?
                </button>
              </div>
              <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordion">
                <div className="accordion-body">
                  <br />- Las tortillas y productos relacionados deben elaborarse con agua potable y utilizar plaguicidas autorizados por la Secretaría de Salud para garantizar la inocuidad de los alimentos.
                  <br />- Los establecimientos deben seguir buenas prácticas de higiene, lo que incluye la limpieza de equipos, utensilios e instalaciones, así como la higiene personal del personal.
                  <br />- Se controlan parámetros como el contenido de humedad, que no debe exceder ciertos niveles en las harinas y productos preparados, para asegurar su calidad y estabilidad.
                  <br />- Las tortillas y productos derivados deben conservarse bajo condiciones adecuadas para evitar su deterioro.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading4">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                  ¿Qué ingredientes están permitidos y cuáles están prohibidos en la elaboración de tortillas según la NOM-187?
                </button>
              </div>
              <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Los ingredientes permitidos son maíz, agua y en algunos casos conservadores y aditivos autorizados, como el carbonato de calcio. Ingredientes prohibidos incluyen cualquier aditivo que no esté aprobado por la norma o que comprometa la calidad e inocuidad del producto, como colorantes o saborizantes no autorizados
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading5">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                  ¿Cómo se deben controlar los niveles de humedad en la masa para tortillas de acuerdo con la norma?
                </button>
              </div>
              <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Los niveles de humedad en la masa para tortillas deben ser controlados para garantizar la calidad del producto. La norma específica que la humedad máxima permitida para la masa es del 15%, evitando así el deterioro del producto.
                  <br />
                  <br />
                  Este control es esencial para mantener la textura adecuada de las tortillas y prevenir la contaminación por hongos o bacterias. Además, el control de humedad contribuye a la estabilidad durante el almacenamiento y transporte de las tortillas
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading6">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                  ¿Por qué es importante la NOM-187-SSA1/SCFI-2002?
                </button>
              </div>
              <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Es crucial porque regula las condiciones sanitarias y de calidad de productos básicos como las tortillas, masa y tostadas, asegurando que sean seguros para el consumo.
                  <br />
                  <br />
                  Esta norma establece límites microbiológicos, requisitos de higiene, y control de humedad, protegiendo la salud pública y promoviendo prácticas adecuadas en la producción.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading7">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                  ¿Cómo afecta la NOM 187 a los productos importados?
                </button>
              </div>
              <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Afecta a los productos importados, como tortillas y harinas, al exigir que cumplan con estándares sanitarios y de etiquetado, garantizando su inocuidad, contenido nutricional y cumplimiento con las normativas de aditivos y transgénicos.
                  <br />
                  <br />
                  Los importadores deben ajustar las etiquetas de sus productos y pasar por un proceso de evaluación de conformidad con una Unidad de Inspección Acreditada para asegurarse de que los productos puedan comercializarse en México sin problemas en aduanas.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading8">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                  ¿Qué pasa si detienen mi producto en aduana?
                </button>
              </div>
              <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordion">
                <div className="accordion-body">
                  En primer lugar, deberás de solicitar la fracción arancelaria del producto. Una vez que tengas la fracción arancelaria y corrobores que le solicitan el cumplimiento de la <b>NOM-187-SSA1/SCFI-2002</b> deberás de contactar a una Unidad de Inspección para obtener una Constancia de Inspección.<br />
                  Debido a que el producto no puede etiquetarse en México, deberás de etiquetar en la aduana o regresar el producto con tu proveedor para que sea etiquetado.<br />
                  Te recomendamos analizar los costos de cada situación y evaluarlos.
                  <br /><br />
                  Una vez que nos contactes, te brindaremos la asesoría y apoyo para que puedas liberar tu producto de la aduana. El proceso será rápido, cada minuto cuenta, por lo que te dejamos nuestro correo (contacto@cecsatrade.mx) y número de contacto (56 5019 0728)
                </div>
              </div>
            </li>
          </ul>
        </div>
        <img className='norm-info__ellipse' src="/img/ellipse-blue.svg" alt="ellipse" />
        <ServicesToOneClick />
        <ServiceSteps
          withNewsLetter={true}
        />
      </section>
    </>
  )
}

export default Nom142