import { useState } from 'react';
import Title from '../Title';
import './styles.scss';
import { Newsletter } from '..';

interface Props {
  withNewsLetter: boolean
}

const ServiceSteps: React.FC<Props> = (props) => {
  const {
    withNewsLetter = true
  } = props;

  const [selected, setSelected] = useState<boolean>(false);
  const [selectedTabItemOnSite, setSelectedTabItemOnSite] = useState<number>(1);
  const [selectedTabItemRemote, setSelectedTabItemRemote] = useState<number>(1);
  return (
    <>
      <section className='service-steps'>
        <img className='service-steps__background-1' loading="lazy" src="/img/steps-background-1.svg" alt="steps background" />
        <Title title={'Conoce los pasos para tramitar el servicio'} />
        <div className='service-steps__switch' onClick={() => setSelected(!selected)}>
          <div className={`service-steps__switch__select service-steps__switch__select${selected ? '--selected' : ''}`} onClick={() => setSelected(!selected)}></div>
          <p className='service-steps__switch__label-1' onClick={() => setSelected(!selected)}>Quiero etiquetar en México</p>
          <p className='service-steps__switch__label-2' onClick={() => setSelected(!selected)}>Quiero etiquetar en Origen</p>
        </div>
        {
          selected ? (
            <div className='service-steps__section'>
              <p className='service-steps__section__title'>En <b>5 pasos</b> puedes obtener tu trámite</p>
              <div className='service-steps__section__tabs'>
                <span className={`service-steps__section__tabs__item service-steps__section__tabs__item${selectedTabItemRemote === 1 ? '--selected' : ''}`} onClick={() => setSelectedTabItemRemote(1)}>Contrato</span>
                <span className={`service-steps__section__tabs__item service-steps__section__tabs__item${selectedTabItemRemote === 2 ? '--selected' : ''}`} onClick={() => setSelectedTabItemRemote(2)}>Solicitud</span>
                <span className={`service-steps__section__tabs__item service-steps__section__tabs__item${selectedTabItemRemote === 3 ? '--selected' : ''}`} onClick={() => setSelectedTabItemRemote(3)}>Inspección</span>
                <span className={`service-steps__section__tabs__item service-steps__section__tabs__item${selectedTabItemRemote === 4 ? '--selected' : ''}`} onClick={() => setSelectedTabItemRemote(4)}>Modificaciones</span>
                <span className={`service-steps__section__tabs__item service-steps__section__tabs__item${selectedTabItemRemote === 5 ? '--selected' : ''}`} onClick={() => setSelectedTabItemRemote(5)}>Constancia</span>
              </div>
              <div className='service-steps__section__separator'></div>
              {
                selectedTabItemRemote === 1 && (
                  <div className='service-steps__section__info-container'>
                    <div className='service-steps__section__info-container__left'>
                      <h2>Firma de contrato de servicios</h2>
                      <div className='service-steps__section__info-container__left__container'>
                        <b>Necesitamos la siguiente documentación Persona Moral:</b>
                        <div>1.- Identificación del representante legal de la empresa.</div>
                        <div>2.- Cédula de identificación fiscal actializada.</div>
                        <div>3.- Copia del Acta Constitutiva con el registro público de la propiedad.</div>
                        <div>4.- Copia del poder notarial (cuando aplique)</div>
                        <div>5.- Correo del representant legal</div>
                        <div>6.- Correo de la persona que podrá darle seguimiento y podrá ver el estatus de la firma del contrato</div>
                      </div>
                      <div className='service-steps__section__info-container__left__container'>
                        <b>Necesitamos la siguiente documentación Persona Física:</b>
                        <div>1.- Identificación oficial</div>
                        <div>2.- Cédula de identificación fiscal actualizada</div>
                        <div>3.- Comprobante de domicilio actualizado no mayor  3 meses</div>
                        <div>4.- Correo par la firma del contrato</div>
                      </div>
                    </div>
                    <div className='service-steps__section__info-container__rigth'>
                      <img className='service-steps__section__info-container__rigth__image' src="/img/contract.svg" alt="contract" />
                    </div>
                  </div>
                )
              }
              {
                selectedTabItemRemote === 2 && (
                  <div className='service-steps__section__info-container'>
                    <div className='service-steps__section__info-container__left'>
                      <h2>Solicitud de servicios</h2>
                      <div className='service-steps__section__info-container__left__container'>
                        <p>Aquí vamos a requerir</p>
                        <div>* Imágenes, diseños, medidas, fotografías, fichas técnicas de tu producto</div>
                        <div>* Llenado de LayOut</div>
                        <br />
                        <div>Todo esto se hace en línea</div>
                        <br />
                        <div>Te daremos una fecha de entrega en la cual se te hará llegar un reporte</div>
                      </div>
                    </div>
                    <div className='service-steps__section__info-container__rigth'>
                      <img className='service-steps__section__info-container__rigth__image' src="/img/request.svg" alt="request" />
                    </div>
                  </div>
                )
              }
              {
                selectedTabItemRemote === 3 && (
                  <div className='service-steps__section__info-container'>
                    <div className='service-steps__section__info-container__left'>
                      <div className='service-steps__section__info-container__left__container'>
                        <p>
                          La unidad de inspección comenzará una revisión de todos los requisitos obligatorios.
                          <br />
                          <br />
                          Te harán llegar un informe indicando las desviaciones que deberán de corregirse.
                          <br />
                          <br />
                          En caso de que no haya desviaciones, se procede directaamente a la emisión de la constancia (certificado)
                        </p>
                      </div>
                    </div>
                    <div className='service-steps__section__info-container__rigth'>
                      <img className='service-steps__section__info-container__rigth__image' src="/img/on-site-visit.svg" alt="on site visit" />
                    </div>
                  </div>
                )
              }
              {
                selectedTabItemRemote === 4 && (
                  <div className='service-steps__section__info-container'>
                    <div className='service-steps__section__info-container__left'>
                      <div className='service-steps__section__info-container__left__container'>
                        <p>
                          Tienes hasta 2 oportunidades para hacer las modificaciones que te solicitan y volver a subir tus correcciones al sistema
                          <br />
                          <br />
                          Una vez que subas tus correcciones, recibirás nuevamente una fecha estimada de entrega
                        </p>
                      </div>
                    </div>
                    <div className='service-steps__section__info-container__rigth'>
                      <img className='service-steps__section__info-container__rigth__image' src="/img/modifications.svg" alt="modifications" />
                    </div>
                  </div>
                )
              }
              {
                selectedTabItemRemote === 5 && (
                  <div className='service-steps__section__info-container'>
                    <div className='service-steps__section__info-container__left'>
                      <div className='service-steps__section__info-container__left__container'>
                        <p>
                          Una vez hechas las correcciones se emitirá la constancia de etiquetado (certificado)
                          <br />
                          <br />
                          felicidades, a partir de este momento tu producto estará en cumplimiento
                        </p>
                      </div>
                    </div>
                    <div className='service-steps__section__info-container__rigth'>
                      <img className='service-steps__section__info-container__rigth__image' src="/img/constancy.svg" alt="constancy" />
                    </div>
                  </div>
                )
              }
            </div>
          ) : (
            <div className='service-steps__section'>
              <p className='service-steps__section__title'>En <b>6 pasos</b> puedes obtener tu trámite</p>
              <div className='service-steps__section__tabs'>
                <span className={`service-steps__section__tabs__item service-steps__section__tabs__item${selectedTabItemOnSite === 1 ? '--selected' : ''}`} onClick={() => setSelectedTabItemOnSite(1)}>Contrato</span>
                <span className={`service-steps__section__tabs__item service-steps__section__tabs__item${selectedTabItemOnSite === 2 ? '--selected' : ''}`} onClick={() => setSelectedTabItemOnSite(2)}>Solicitud</span>
                <span className={`service-steps__section__tabs__item service-steps__section__tabs__item${selectedTabItemOnSite === 3 ? '--selected' : ''}`} onClick={() => setSelectedTabItemOnSite(3)}>Importación</span>
                <span className={`service-steps__section__tabs__item service-steps__section__tabs__item${selectedTabItemOnSite === 4 ? '--selected' : ''}`} onClick={() => setSelectedTabItemOnSite(4)}>Etiquetado</span>
                <span className={`service-steps__section__tabs__item service-steps__section__tabs__item${selectedTabItemOnSite === 5 ? '--selected' : ''}`} onClick={() => setSelectedTabItemOnSite(5)}>Visita en sitio</span>
                <span className={`service-steps__section__tabs__item service-steps__section__tabs__item${selectedTabItemOnSite === 6 ? '--selected' : ''}`} onClick={() => setSelectedTabItemOnSite(6)}>Dictamen</span>
              </div>
              <div className='service-steps__section__separator'></div>
              {
                selectedTabItemOnSite === 1 && (
                  <div className='service-steps__section__info-container'>
                    <div className='service-steps__section__info-container__left'>
                      <h2>Firma de contrato de servicios</h2>
                      <div className='service-steps__section__info-container__left__container'>
                        <b>Necesitamos la siguiente documentación Persona Moral:</b>
                        <div>1.- Identificación del representante legal de la empresa.</div>
                        <div>2.- Cédula de identificación fiscal actializada.</div>
                        <div>3.- Copia del Acta Constitutiva con el registro público de la propiedad.</div>
                        <div>4.- Copia del poder notarial (cuando aplique)</div>
                        <div>5.- Correo del representant legal</div>
                        <div>6.- Correo de la persona que podrá darle seguimiento y podrá ver el estatus de la firma del contrato</div>
                      </div>
                      <div className='service-steps__section__info-container__left__container'>
                        <b>Necesitamos la siguiente documentación Persona Física:</b>
                        <div>1.- Identificación oficial</div>
                        <div>2.- Cédula de identificación fiscal actualizada</div>
                        <div>3.- Comprobante de domicilio actualizado no mayor  3 meses</div>
                        <div>4.- Correo par la firma del contrato</div>
                      </div>
                    </div>
                    <div className='service-steps__section__info-container__rigth'>
                      <img className='service-steps__section__info-container__rigth__image' src="/img/contract.svg" alt="contract" />
                    </div>
                  </div>
                )
              }
              {
                selectedTabItemOnSite === 2 && (
                  <div className='service-steps__section__info-container'>
                    <div className='service-steps__section__info-container__left'>
                      <h2>Solicitud de servicios</h2>
                      <div className='service-steps__section__info-container__left__container'>
                        <p>Aquí vamos a requerir</p>
                        <div>* Proforma del pedimento</div>
                        <div>* Facturas que amparen la mercancía</div>
                        <div>* Llenado de LayOut</div>
                        <br />
                        <div>Todo esto se hace en línea</div>
                        <br />
                        <div>Tus folios saldrán validados en un tiempo máximo de 24 horas para que puedas pagar impuestos y hacer eñ desaduanamiento de las mercancías.</div>
                      </div>
                    </div>
                    <div className='service-steps__section__info-container__rigth'>
                      <img className='service-steps__section__info-container__rigth__image' src="/img/request.svg" alt="request" />
                    </div>
                  </div>
                )
              }
              {
                selectedTabItemOnSite === 3 && (
                  <div className='service-steps__section__info-container'>
                    <div className='service-steps__section__info-container__left'>
                      <div className='service-steps__section__info-container__left__container'>
                        <p>Aquí vamos a requerir</p>
                        <div>* Proforma del pedimento</div>
                        <div>* Facturas que amparen la mercancía</div>
                        <div>* Llenado de LayOut</div>
                        <br />
                        <div>Todo esto se hace en línea</div>
                        <br />
                        <div>Tus folios saldrán validados en un tiempo máximo de 24 horas para que puedas pagar impuestos y hacer eñ desaduanamiento de las mercancías.</div>
                      </div>
                    </div>
                    <div className='service-steps__section__info-container__rigth'>
                      <img className='service-steps__section__info-container__rigth__image' src="/img/importation.svg" alt="importation" />
                    </div>
                  </div>
                )
              }
              {
                selectedTabItemOnSite === 4 && (
                  <div className='service-steps__section__info-container'>
                    <div className='service-steps__section__info-container__left'>
                      <div className='service-steps__section__info-container__left__container'>
                        <p>
                          La mercancía deberá de ser etiquetada en su totalidad de acuerdo a la norma que indica el pedimento.
                          <br />
                          <br />
                          Puedes pedir asesoría para saber cómo etiquetar.
                          <br />
                          <br />
                          Posterior a ello deberás solicitar la visit en sitio para corroborar que todas las mercancías estan etiquetadas.
                        </p>
                      </div>
                    </div>
                    <div className='service-steps__section__info-container__rigth'>
                      <img className='service-steps__section__info-container__rigth__image' src="/img/labeling.svg" alt="labeling" />
                    </div>
                  </div>
                )
              }
              {
                selectedTabItemOnSite === 5 && (
                  <div className='service-steps__section__info-container'>
                    <div className='service-steps__section__info-container__left'>
                      <div className='service-steps__section__info-container__left__container'>
                        <p>
                          Personal de la unidad de inspección visitará tus instalaciones para comprobar que la mercancía se encuentre correctamente etiquetada <b>antes</b> de su venta.
                          <br />
                          <br />
                          <b>Importante</b>, no podrá comercializarse la mercancía hasta que no se haga esta visita.
                        </p>
                      </div>
                    </div>
                    <div className='service-steps__section__info-container__rigth'>
                      <img className='service-steps__section__info-container__rigth__image' src="/img/on-site-visit.svg" alt="on site visit" />
                    </div>
                  </div>
                )
              }
              {
                selectedTabItemOnSite === 6 && (
                  <div className='service-steps__section__info-container'>
                    <div className='service-steps__section__info-container__left'>
                      <div className='service-steps__section__info-container__left__container'>
                        <p>
                          Una vez concluida la visita y que los productos se encuentren correctaamente etiquetados ya podrás comercializarla.
                          <br />
                          <br />
                          Además, recibirás un dictamen que respalde la importación de tu mercancía.
                        </p>
                      </div>
                    </div>
                    <div className='service-steps__section__info-container__rigth'>
                      <img className='service-steps__section__info-container__rigth__image' src="/img/dictum.svg" alt="dictum" />
                    </div>
                  </div>
                )
              }
            </div>
          )
        }
        <img className='service-steps__background-2' loading="lazy" src="/img/steps-background-2.svg" alt="steps background" />
      </section>
      {
        withNewsLetter && (
          <Newsletter />
        )
      }
    </>
  )
}

export default ServiceSteps