import { useContext, useEffect, useRef } from 'react';
import { AllDigital, Button, DigitalAdvantages, OnlineServices, QuestionsService, ServiceSteps } from '../../components';
import './styles.scss';
import { AppContext } from '../../context/AppContext';
import { Helmet } from 'react-helmet';

const PrivateWarehouse: React.FC = () => {
  const circle1 = useRef<HTMLDivElement>(null);
  const circle2 = useRef<HTMLDivElement>(null);
  const circle3 = useRef<HTMLDivElement>(null);
  const circle4 = useRef<HTMLDivElement>(null);
  const circle5 = useRef<HTMLDivElement>(null);
  const circle6 = useRef<HTMLDivElement>(null);
  const circle7 = useRef<HTMLDivElement>(null);
  const line1 = useRef<HTMLDivElement>(null);
  const line2 = useRef<HTMLDivElement>(null);
  const line3 = useRef<HTMLDivElement>(null);
  const line4 = useRef<HTMLDivElement>(null);
  const line5 = useRef<HTMLDivElement>(null);
  const line6 = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const cambiarClase = () => {
      if (circle1.current) {
        circle1.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle1.current) {
              circle1.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle2.current) {
        circle2.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle2.current) {
              circle2.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle3.current) {
        circle3.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle3.current) {
              circle3.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle4.current) {
        circle4.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle4.current) {
              circle4.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle5.current) {
        circle5.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle5.current) {
              circle5.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle6.current) {
        circle6.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle6.current) {
              circle6.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle7.current) {
        circle7.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle7.current) {
              circle7.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (line1.current) {
        line1.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line1.current) {
              line1.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      if (line2.current) {
        line2.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line2.current) {
              line2.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      if (line3.current) {
        line3.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line3.current) {
              line3.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      if (line4.current) {
        line4.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line4.current) {
              line4.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      if (line5.current) {
        line5.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line5.current) {
              line5.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      if (line6.current) {
        line6.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line6.current) {
              line6.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      timeoutRef.current = setTimeout(cambiarClase, 16000);
    };

    cambiarClase();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [timeoutRef]);

  const {
    setModalOpen,
    modalOpen
  } = useContext(AppContext);

  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'Etiquetado en México Almacén Particular';
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Etiqueta en tu propio almacén. Durante los primeros 30 dias del despacho. Fácil y rápido"
        />
      </Helmet>
      <section className='private-warehouse'>
        <div className='private-warehouse__header'>
          <div className='private-warehouse__header__top'>
            <div className='private-warehouse__header__top__item'>Para importadores principiantes</div>
          </div>
          <div className='private-warehouse__header__info'>
            <div className='private-warehouse__header__info__left'>
              <h1>Etiquetado en México Almacén Particular</h1>
              <p>Etiqueta en tu propio almacén. Durante los primeros 30 dias del despacho. Fácil y rápido</p>
              <Button text={'Solicitar'} onClick={() => setModalOpen(!modalOpen)} type={'black'} />
            </div>
            <div className='private-warehouse__header__info__image-container'>
              <img className='private-warehouse__header__info__image-container__image' src="/img/private-warehouse.svg" alt="private-warehouse" />
            </div>
          </div>
        </div>
        <div className='private-warehouse__legal'>
          <h2>Cumple con las normas en México</h2>
          <div className='private-warehouse__legal__section-1'>
            <p>Esta opción es ideal para los importadores que ya tienen más ed 2 años en el padrón de importadores y han hecho importaciones por más de $100,000 USD en los últimos 12 meses.
              <br />
              <br />
              Habiendo cumplido los requisitos enteriores, podrás optar por esta pción y etiquetar en tu propio almacén a lo largo de la república mexicana.
            </p>
            <p>Una vez que llegue tu mercancía al almacén, deberás de etiquetar conforme a las Normas Oficiales Mexicanas.
              <br />
              <br />
              Personal de CECSA te visitará en tus instalaciones para corroborar que la mercancía se encuentre totalmente etiquetada y posterior a ello te emitirá un ditamen de cumplimiento con el cual podrás movilizar tu mercancía.
            </p>
          </div>
          <div className='private-warehouse__legal__section-2'>
            <div className='private-warehouse__legal__section-2__item'>
              <img src="/img/legal-1.svg" alt="legal-1" />
              <h3>Presencia Nacional</h3>
              <p>Contamos con un equipo de personas en todo México que podrá apoyarte y hacer las inspecciones de etiquetado.</p>
            </div>
            <div className='private-warehouse__legal__section-2__item'>
              <img src="/img/legal-2.svg" alt="legal-2" />
              <h3>Trabajamos con tu agente aduanal</h3>
              <p>para facilitar el trabajo y gestión de los requisitos que te solicita la aduana.</p>
            </div>
            <div className='private-warehouse__legal__section-2__item'>
              <img src="/img/legal-3.svg" alt="legal-3" />
              <h3>Acreditación y Aprobación</h3>
              <p>En CECSA contamos con las licencias correspondientes de acuerdo con la Ley de la Infraestructura de la Calidad.
                <br />
                <br />
                Trabaja sólo con empresas confirables.
              </p>
            </div>
          </div>
        </div>
        <AllDigital />
        <DigitalAdvantages />
        <div className='constancy__fundament'>
          <h2>Fundamentos</h2>
          <p className='constancy__fundament__text'>
            ACUERDO por el que la Secretaría de Economía emite Reglas y criterios de carácter general en materia de comercio exterior.
            <br />
            <br />
            2.4.8 Para las mercancías que se listan en el numeral 3 del Anexo 2.4.1 del presente ordenamiento, únicamente se exigirá que las etiquetas o los medios adheribles permitidos, contengan la información establecida en la NOM correspondiente, y que al momento de su introducción al territorio nacional se encuentren adheridas, pegadas, cosidas, colgadas o colocadas en las mercancías como se establezca en cada una de las normas, de tal modo que impida su desprendimiento inmediato, y asegure su permanencia en las mismas hasta llegar al usuario.
            <br />
            <br />
            Tratándose de las mercancías que se listan en las fracciones I, II, III, VII, VIII, IX, X, XI, XII y XIV, el importador podrá optar por cualquiera de las alternativas siguientes para comprobar el cumplimiento de las NOMs de información comercial a que se refieren dichas fracciones:
            <br />
            <br />
            III. Dar cumplimiento a dichas NOMs de información comercial en territorio nacional, siempre que traslade las mercancías a un domicilio particular, en el cual una unidad de verificación o inspección, según corresponda, acreditada y aprobada en los términos de la LIC, realizará ya sea la verificación o inspección, o la recolección de muestras para su posterior verificación en materia de veracidad de la información comercial.
            <br />
            <br />
            Los importadores que opten por la alternativa prevista en esta fracción deberán:
            a) Estar inscritos y activos en el padrón de importadores previsto en el artículo 59 fracción IV de la LA, con una antigüedad no menor a 2 años;
            <br />
            <br />
            b) Haber importado al país mercancías con un valor equivalente en moneda nacional a 100,000 dólares de los Estados Unidos de América, en una o varias operaciones, durante los 12 meses anteriores a la fecha en que pretendan ejercer esta opción;
            <br />
            <br />
            c) Declarar en el pedimento de importación, antes de activar el mecanismo de selección
            automatizado, la clave que se establezca en las Reglas del SAT para identificar las
            mercancías que se encuentren en los supuestos a que se refiere esta fracción, y
            <br />
            <br />
            d) Transmitir en documento electrónico o digital como anexo al pedimento de importación, copia de la solicitud de servicios, así como el contrato para la prestación del servicio, con el número de folio correspondiente. El número de solicitud o folio correspondiente se deberá declarar en el pedimento, en el que se señale la denominación y clave de acreditación de la unidad de verificación o inspección según corresponda, la fecha programada para la verificación o inspección, misma que no podrá ser posterior a treinta días naturales contados a partir de la importación de las mercancías, así como el domicilio en el que se llevará a cabo ésta.
            <br />
            <br />
            <b>Dictamen</b><br />
            Documento que se emite a los importadores como resultado de la evaluación de la conformidad efectuada durante la visita de verificación realizada en sitio, en el que se evidencia el cumplimiento o no cumplimientode los requisitos establecidos en las Normas Oficiales Mexicanas, cuando sea aplicable de conformidad con el procedimiento establecido en el Acuerdo por el que la Secretaría de Economía emite reglas y criterios de carácter general en materia de Comercio Exterior publicado en el Diario Oficial de la Federación el 31 de diciembre de 2012 y sus modificaciones.
          </p>
          <div className='constancy__fundament__image-container'>
            <img src="/img/fundament.svg" alt="Basis" className='constancy__fundament__image-container__image' />
          </div>
        </div>
        <OnlineServices />
        <ServiceSteps
          withNewsLetter={true}
        />
        <QuestionsService
          questions={[
            {
              question: '¿Qué requiero para tramitar la solicitud de servicios?',
              response: <>Primeramente, debes de contar con el contrato de servicios firmado.
                <br />
                <br />
                Requieres contar con la proforma del pedimento, las facturas comerciales que amparen la mercancía y el llenado de un Lay Out.
                <br />
                <br />
                Todo lo anterior, se puede hacer en línea o por correo con alguno de nuestros asesores.</>
            },
            {
              question: '¿Cuánto cuesta tramitar una solicitud de servicios?',
              response: <>Dependerá del número de modelos, partidas que tenga tu pedimento y de las normas que requieras. Con base a la información anterior te entregaremos un presupuesto.
                <br />
                <br />
                Solicita una cotización con uno de nuestros asesores</>
            },
            {
              question: '¿Cuánto tiempo requiero para tramitar una solicitud de servicios?',
              response: <>Una vez que subes tu información al portal, deberá de ser validada la información por algunos de nuestros asesores. Una vez que la información esta validad tendrás tu solicitud de servicios. El tiempo máximo de espera es de 30 minutos. </>
            },
            {
              question: '¿En cuánto tiempo se validan mis folios?',
              response: <>Es importante mencionar que si tramitas tu servicio antes de la 1:00 pm tendrás validados tus folios ese mismo día.
                <br />
                <br />
                Por caso contrario, en caso de que tramite tu servicio después de la 1:00 pm tus folios saldrán validados al siguiente día.</>
            },
            {
              question: '¿Dónde puedo consultar mis folios validados?',
              response: <>Puede hacerlo a través de dos medios:
                <br />
                1) En el portal de CECSA, ubicando tu número de pedimento a través de la liga <a rel="noreferrer" href="https://app.cecsatrade.mx" target='_blank'>https://app.cecsatrade.mx</a>
                <br />
                2) En el siguiente portal del SAT <a rel="noreferrer" href="https://aplicacionesc.mat.sat.gob.mx/SOIANET/oia_wfConsultaRapNoms.aspx" target='_blank'>https://aplicacionesc.mat.sat.gob.mx/SOIANET/oia_wfConsultaRapNoms.aspx</a>
                <br />
                3) O preguntando con alguno de nuestros asesores vía WhatsApp</>
            },
            {
              question: '¿Cuánto tiempo tengo para desaduanar mis mercancías?',
              response: <>Una vez que salgan validados tus folios, cuentas con 30 días para desaduanar y usar los folios que te proporcionamos en CECSA.
                <br />En caso de que importación se demore más de 30 dias, deberás de solicitar nuevamente folios. </>
            },
            {
              question: '¿Debo de tener un contrato con un almacén y otro con CECSA?',
              response: <>No, en este caso no es requerido un contrato con un almacén, ya que podrás etiquetar en tu propio almacén de conformidad con el ACUERDO por el que la Secretaría de Economía emite Reglas y criterios de carácter general en materia de comercio exterior.</>
            },
            {
              question: '¿Cuál es el procedimiento para liberar mis mercancías y poderlas vender?',
              response: <>Una vez que la mercancía llegue a tú almacén deberás de etiquetarlas conforme a la Norma Oficial Mexicana correspondiente. Puedes pedir asesoría a CECSA para saber cómo etiquetar o para que le demos las indicaciones al personal del almacén.
                <br />
                <br />
                Una vez que estén etiquetadas, deberás de solicitar a CECSA que hagan una visita de inspección para corroborar que la totalidad de los productos se encuentren etiquetados.
                <br />
                <br />
                Una vez que algunos de nuestros inspectores visiten tu almacén y te de una opinión favorable, entonces ya podrás comercializar tus mercancías.
                <br />
                <br />
                Por el contrario, si la mercancía estuviera mal etiquetada deberás de hacer las correcciones, por eso es importante solicitar asesoría previa. Y se podrá agendar hasta una segunda visita.  </>
            },
            {
              question: '¿Por qué en esta modalidad si puedo trasladar mis mercancías a mi propio almacén?',
              response: <>Para poder llevar las mercancías a tu propio almacén deberás de contar con estos dos requisitos:
                <br />
                <br />
                1) Tener al menos 2 años en el padrón de importadores<br />
                2) Haber importado al menos 100,000 USD en 1 o varias operaciones en los últimos 12 meses.
                <br />
                <br />
                Por lo que de cumplir con estos dos requisitos podrás etiquetar en tu propio almacén.
                <br />
                <br />
                Esto de conformidad con el ACUERDO por el que la Secretaría de Economía emite Reglas y criterios de carácter general en materia de comercio exterior.
                <br />
                <br />
                Puedes pedir asesoría con tu agente aduanal o con personal de CECSA para orientarte.</>
            },
            {
              question: '¿Qué tipo de régimen y clave debe de tener mi pedimento?',
              response: <>El pedimento de importación deberá de contener la Clave A4 con el Régimen DFI
                <br />
                DEPÓSITO FISCAL de conformidad con el Anexo 22 de las reglas generales de comercio exterior</>
            },
            {
              question: '¿Qué identificador a nivel partida debe de traer mi pedimento?',
              response: <>El identificador a nivel partida que debe de tener los productos sujetos a cumplimiento de normas oficiales mexicanas es PB el cual significa <u>CUMPLIMIENTO DE NORMA OFICIAL MEXICANA PARA SU VERIFICACIÓN DENTRO DEL TERRITORIO NACIONAL, EN UN DOMICILIO PARTICULAR</u> de conformidad con el Anexo 22 de las reglas generales de comercio exterior</>
            },
            {
              question: '¿Mi agente aduanal me puede orientar en este trámite?',
              response: <>Si, recuerda que ellos son tus representantes y los responsables de clasificar tus mercancías y orientarte en todo el proceso de importación.
                <br />
                <br />
                También puedes pedir ayuda a CECSA</>
            },
            {
              question: '¿CECSA puede liberar la mercancía de mi propio almacén?',
              response: <>Si, ese es nuestro trabajo, pero primero necesitamos hacer la visita de inspección para corroborar que tus mercancías se encuentren etiquetadas.
                <br />
                <br />
                Una vez hecho lo anterior, te emitiremos el dictamen de cumplimiento, el cual deberás de resguardar por cada pedimento para demostrar que se realizó la inspección de las mercancías.  </>
            },
            {
              question: '¿Cuándo se emite el dictamen de cumplimiento?',
              response: <>El dictamen de cumplimiento se emite una vez que hayamos hecho la visita de inspección y corroborado que la totalidad de tus mercancías se encuentran bien etiquetadas.
                <br />
                <br />
                Recuerda que esto es indispensable para que puedas comercializarlas.</>
            },
            {
              question: '¿Cuento con un periodo de tiempo especifico para hacer el etiquetado?',
              response: <>Si, cuentas con un periodo máximo de 30 dias una vez desaduanadas las mercancías para dar cumplimiento con el etiquetado.
                <br />
                <br />
                En caso de no cumplir con el etiquetado en los 30 dias posteriores al desaduanamiento se emitirá una Negación de Dictamen y perderás el derecho a usar estar alternativa de etiquetado en los próximos 12 meses.
                <br />
                <br />
                Por lo que es de suma importación que se de cumplimiento con el etiquetado en el periodo establecido.
                <br />
                <br />
                De conformidad con el ACUERDO por el que la Secretaría de Economía emite Reglas y criterios de carácter general en materia de comercio exterior:
                <br />
                <br />
                <u>En caso en que se detecte el incumplimiento de etiquetado en territorio nacional conforme lo dispuesto en las fracciones II (etiquetado en un almacén fiscal)  y III  (etiquetado en un almacén particular) del presente numeral, las empresas no podrán acogerse a lo previsto en las mismas por un periodo de 12 meses, contado a partir de que sea detectado, sin perjuicio de las demás disposiciones aplicables.</u></>
            },
            {
              question: '¿Requiero hacer este proceso para cada importación?',
              response: <>Si, cada vez que hagas una importación es requerido tramitar nuevamente una solicitud de servicios, ya que el dictamen solo cubre la importación para la cual fue tramitada. </>
            }
          ]}
        />
      </section>
    </>
  )
}

export default PrivateWarehouse