/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { BenefictsAndWhoDifferent, ExcusiveAttention, JsonLd, NormInfo, References, ServiceSteps, ServicesToOneClick } from '../../components';
import { Helmet } from 'react-helmet';
import './styles.scss';

const Nom142: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-142-SCFI/SSA1-2014 - Bebidas alcoholicas';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org", "@type": "WebPage", "@id":
              "https://www.cecsatrade.mx/nom-142-scfi-ssa1-2014", "url":
              "https://www.cecsatrade.mx/nom-142-scfi-ssa1-2014", "name":
              "NOM-142-SCFI/SSA1-2014", "description": `La NOM-142-SSA1/SCFI-2014 establece los requisitos sanitarios, comerciales y de etiquetado para bebidas alcohólicas en México, garantizando la seguridad y calidad del producto para proteger al consumidor.`, "isPartOf": {
                "@id":
                  "https://www.cecsatrade.mx/#/schema/WebSite/1"
              }, "datePublished":
              "2023-01-31T08:08:40-06:00", "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": { "@id": "https://www.cecsatrade.mx/assets/142.svg" }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="La NOM-142-SSA1/SCFI-2014 establece los requisitos sanitarios, comerciales y de etiquetado para bebidas alcohólicas en México, garantizando la seguridad y calidad del producto para proteger al consumidor."
        />
      </Helmet>
      <section className='norm-info'>
        <div className='sub-header'>
          <div className='sub-header__spacer'></div>
          <div className='sub-header__spacer'></div>
          {/* <a className='sub-header__button' href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a> */}
          <a
            className='sub-header__email'
            href="mailto:asesoria@cecsatrade.mx"
            target="_blank">
            <img loading="lazy" src="/img/mail.svg" alt="Mail" />
            |
            Mail de CECSA
          </a>
        </div>
        <h1 className='norm-info__title'>NOM-142-SSA1/SCFI- 2014 BEBIDAS ALCOHÓLICAS</h1>
        <div className='norm-info__header norm-info__header--142'>
          <button onClick={() => {
            const element = document.getElementById("benefits");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
          }}>Beneficios</button><a href='https://wa.me/message/YZR3TYWZRARDH1' target='_blank'>Quiero cumplir con la NOM</a>
          <button onClick={() => {
            const element = document.getElementById("preguntas-frecuentes");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
            }
          }}>Preguntas frecuentes</button>
        </div>
        <div className='norm-info__image-container'>
          {/* <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-003-asset.svg" alt="NOM 003 Logo" /> */}
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Sé el primero en saber...</h2>
            <br />
            <p>
              La Norma de bebidas alcohólicas <b>(NOM-142)</b> establece las especificaciones sanitarias y comerciales para el etiquetado adecuado. Exige que las etiquetas incluyan información clara y veraz sobre el contenido, origen y características del producto, ayudando al consumidor a tomar decisiones informadas.
              <br />
              <br />
              Esta <b>Norma Oficial Mexicana para bebidas alcohólicas</b> es de <b>carácter obligatorio</b> ante los productos comercializados en México, ya sean de origen nacional o de procedencia extranjera.
            </p>
          </div>
        </div>
        <div className='norm-info__image-container norm-info__image-container--black'>
          <div className='norm-info__image-container__img-container'>
            <img className='norm-info__image-container__img-container__image' loading="lazy" src="/img/botellas-acohol.png" alt="botellas-acohol" />
          </div>
          <div className='norm-info__image-container__text norm-info__image-container__text--center norm-info__image-container__text--small-margin'>
            <p style={{ textAlign: 'left' }}>
              La NOM-142-SSA1/SCFI-2014 establece los requisitos sanitarios, comerciales y de etiquetado para bebidas alcohólicas en México, garantizando la seguridad y calidad del producto para proteger al consumidor.
            </p>
            <button onClick={() => {
              const element = document.getElementById("benefits");

              if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
              }
            }}>Beneficios</button>
          </div>
        </div>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Entrada en vigor</h2>
            <br />
            <p>
              La <b>NOM-142-SCFI/SSA1-2014. Especificaciones sanitarias. Etiquetado sanitario y comercial para bebidas alcohólicas.</b> Fue publicada en el <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5386313&fecha=23/03/2015#gsc.tab=0" target='_blank'>Diario Oficial de la Federación</a> el 23 de marzo de 2015, pero entró en vigor el 21 de julio 2015.
            </p>
            <br />
            <a className='norm-info__image-container__anchorage' href="https://www.dof.gob.mx/nota_detalle.php?codigo=5269348&fecha=19/09/2012#gsc.tab=0" target='_blank'>Más información de esta NOM</a>
          </div>
        </div>
        <img className='norm-info__ellipse' src="/img/ellipse-black.svg" alt="ellipse" />
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Aplicación de la NOM-142</h2>
            <br />
            <p>La <b>NOM-142</b> regula aspectos como los ingredientes permitidos, los límites máximos de contenido de alcohol, las advertencias sanitarias en el etiquetado, y las especificaciones sobre el envase y presentación de productos. </p>
            <br />
            <p>
              <div className='norm-info__image-container__aplications-015'>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-142-1.svg" alt="tequila" />
                  <span><b>Tequila</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-142-2.svg" alt="mezcal" />
                  <span><b>Mezcal</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-142-3.svg" alt="ron" />
                  <span><b>Ron</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-142-4.svg" alt="vino" />
                  <span><b>Vino</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-142-5.svg" alt="cerveza" />
                  <span><b>Cerveza</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-142-6.svg" alt="whisky" />
                  <span><b>Whisky</b></span>
                </div>
              </div>
              <br />
              Estas bebidas deben ser destinadas al consumo directo.
            </p>
          </div>
        </div>
        <ExcusiveAttention />
        <div className='norm-info__image-container norm-info__image-container--blue-050'>
          <img className='norm-info__image-container__image-background-1' loading="lazy" src="/img/background-circle-blue-1.svg" alt="backghround 1" />
          <div className='norm-info__image-container__text'>
            <h2>La NOM-142-SCFI/SSA1-2014 no aplica a:</h2>
            <br />
            <p>La presente <b>Norma Oficial Mexicana</b> no es aplicable a lo siguiente:</p>
            <br />
            <div className='norm-info__image-container__text__data-item-container-004'>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-142-1.svg" alt="juguetes-armas" />
                <span><b>Bebidas preparadas</b></span>
              </div>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-142-2.svg" alt="juguetes-armas" />
                <span><b>Vinagre</b></span>
              </div>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-142-3.svg" alt="juguetes-armas" />
                <span><b>Productos fermentados</b></span>
              </div>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-142-4.svg" alt="juguetes-armas" />
                <span><b>Productos medicinales</b></span>
              </div>
            </div>
            <br />
          </div>
          <img className='norm-info__image-container__image-background-2' loading="lazy" src="/img/background-circle-blue-2.svg" alt="backghround 2" />
        </div>
        <div className='norm-info__image-container norm-info__image-container--black norm-info__image-container--margin norm-info__image-container--050' style={{ marginBottom: '30rem', overflow: 'visible', zIndex: '7' }}>
          <h2>DEFINICIONES</h2>
          <div className="norm-info__050-definitions__item">
            <span>Alcohol etílico</span>
            <p>Producto fermentado de azúcares o almidones (caña de azúcar, mieles, jarabes) que es destilado y rectificado.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Añejamiento</span>
            <p>Proceso de maduración de al menos un año en recipientes de roble blanco o encino con capacidad máxima de 700 litros.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Bebida alcohólica destilada</span>
            <p>Producto obtenido por destilación de líquidos fermentados a partir de materias vegetales, donde la fermentación principal es alcohólica. </p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Bebida alcohólica fermentada</span>
            <p>Producto resultante de la fermentación alcohólica de materias primas vegetales, con adición permitida de ingredientes y aditivos según normativas.</p>
          </div>
          <h2>CONTENIDO DE LA ETIQUETA</h2>
          <br />
          <br />
          <p>El etiquetado debe ser claro y legible, en español y con colores que contrasten para garantizar su visibilidad. Además, debe permanecer intacto y bien fijado en el envase hasta su consumo.</p>
          <br />
          <div className="norm-info__015-definitions__item norm-info__015-definitions__item--images">
            <img src="/img/142-logo-1.svg" alt="141-logo-1" />
            <img src="/img/142-logo-2.svg" alt="141-logo-2" />
            <img src="/img/142-logo-3.svg" alt="141-logo-3" />
            <img src="/img/142-logo-4.svg" alt="141-logo-4" />
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Nombre del producto</span>
            <p>Denominación genérica y marca comercial.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Contenido Alcohólico</span>
            <p>Debe indicarse el porcentaje de alcohol en volumen (% Alc. Vol.)</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>País de Origen</span>
            <p>Leyendas como "Hecho en…" o "Producto de…" para identificar el país de fabricación.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Responsable</span>
            <p>Nombre, razón social y domicilio del fabricante o importador.</p>
          </div>
          <br />
          <br />
          <h2>Leyendas Precautorias</h2>
          <br />
          <br />
          <p className='norm-info__image-container__text-center'>
            Incluir los símbolos de prohibición de consumo para menores de edad, mujeres embarazadas y conducción bajo los efectos del alcohol.
          </p>
          <p className='norm-info__image-container__text-center'>
            Debe incluirse la leyenda
            <b>"EL ABUSO EN EL CONSUMO DE ESTE PRODUCTO ES NOCIVO PARA LA SALUD".</b>
          </p>

          <div className="norm-info__015-definitions__item norm-info__015-definitions__item--column">
            <div className="norm-info__015-definitions__item__container">
              <p>
                <b>Envase</b>
                <br />
                Deben usarse envases sanitarios como vidrio, PET, aluminio, o barriles de acero inoxidable.
                <br />
                <br />
                <b>Lista de Ingredientes</b>
                <br />
                Deben declararse los ingredientes que puedan causar alergias o intolerancias, como cereales con gluten, huevo, leche, cacahuates, etc.
              </p>
            </div>
            <img src="/img/botella-1.png" alt="botella" />
          </div>
          <p className='norm-info__image-container__text-center'>
            <br />
            <br />
            Cumplir con estos puntos asegura que el <b>etiquetado de las bebidas alcohólicas</b> sea claro, <b>seguro y confiable</b> para los consumidores en México.
            <br />
            <br />
          </p>
          <img className='norm-info__050-definitions__footer' loading="lazy" src="/img/definitions-050-footer.svg" alt="footer" />
        </div>

        <BenefictsAndWhoDifferent
          title={'CERTIFICA TUS PRODUCTOS'}
          text={'Contactanos, nosotros te apoyamos en cada paso que debes realizar para cumplir conforme lo establecido de manera nacional en materia de comercial dentro de la industria.'}
        />
        <References
          items={[
            (
              <li>
                <img src="/img/list-item-142.png" alt="juguete" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002#gsc.tab=0" target='_blank'>NOM-008-SCFI-2002</a>, Sistema General de Unidades de Medida.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-142.png" alt="juguete" />
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=4936602&fecha=06/11/2006#gsc.tab=0" target='_blank'>NOM-030-SCFI-2006</a>, Información comercial. Declaración de cantidad en la etiqueta - Especificaciones.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-142.png" alt="juguete" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=4879610&fecha=16/08/1995#gsc.tab=0" target='_blank'>NOM-117-SSA1-1994</a>, Bienes y Servicios. Método de prueba para la determinación de cadmio, arsénico, plomo, estaño, cobre, fierro, zinc y mercurio en alimentos, agua potable y agua purificada por espectrometría de absorción atómica.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-142.png" alt="juguete" />
                NMX-V-004-NORMEX-2013, Bebidas alcohólicas - Determinación de Furfural-Métodos de Ensayo (Prueba).
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-142.png" alt="juguete" />
                NMX-V-005-NORMEX-2013, Bebidas alcohólicas - Determinación de aldehídos, ésteres, metanol y alcoholes superiores-Métodos de ensayo (prueba).
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-142.png" alt="juguete" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=5551813&fecha=05/03/2019#gsc.tab=0" target='_blank'>NMX-V-013-NORMEX-2013</a>, Bebidas alcohólicas - Determinación del contenido alcohólico (por ciento de alcohol en volumen a 20 °C (% Alc. Vol.) - Métodos de ensayo (prueba)
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-142.png" alt="juguete" />
                NMX-V-027-NORMEX-2009, Bebidas alcohólicas - Determinación de anhídrido sulfuroso, dióxido de azufre (SO2) libre y total - Métodos de ensayo (Prueba).
              </li>
            )
          ]}
        />
        <NormInfo
          DOFDate='23/03/2015'
          VigorDate='21/07/2015'
          responsible='SECRETARIA DE SALUD y SECRETARIA DE ECONOMIA'
        />
        <div className='norm-info__questions' id='preguntas-frecuentes'>
          <h2 className="norm-info__container__sub-title">Preguntas frecuentes</h2>
          <ul className="accordion norm-info__container__not-transform-ul" id="accordion">
            <li className="accordion-item">
              <div id="heading1">
                <button className="accordion-button norm-info__container__accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                  ¿A qué productos aplica la NOM-142?
                </button>
              </div>
              <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordion">
                <div className="accordion-body">
                  La norma aplica a todas las bebidas alcohólicas, ya sean nacionales o importadas, que se comercialicen en territorio mexicano.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                  ¿Qué sucede si un producto no cumple con la NOM-142?
                </button>
              </div>
              <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordion">
                <div className="accordion-body">
                  El incumplimiento de la norma puede generar sanciones, como multas impuestas por las autoridades competentes, la retirada del producto del mercado o la prohibición de su comercialización.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading3">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                  ¿Es obligatorio incluir advertencias sanitarias en las etiquetas?
                </button>
              </div>
              <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Sí, es obligatorio incluir advertencias sanitarias en el etiquetado de las bebidas alcohólicas. La frase "El abuso en el consumo de este producto es nocivo para la salud" es un requisito indispensable para proteger a los consumidores.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading4">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                  ¿Cómo debe declararse el contenido de alcohol?
                </button>
              </div>
              <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordion">
                <div className="accordion-body">
                  El porcentaje de alcohol en volumen debe indicarse de forma clara y visible en la etiqueta. Esto ayuda a los consumidores a conocer el grado alcohólico del producto que están adquiriendo.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading5">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                  ¿Qué leyendas o advertencias adicionales pueden incluirse en las etiquetas de bebidas alcohólicas?
                </button>
              </div>
              <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Además de las advertencias sanitarias obligatorias, las etiquetas pueden incluir otras leyendas para reforzar la seguridad, como “Prohibida su venta a menores de edad” o “No consumas si vas a conducir “ y "Prohibido el consumo por mujeres embarazadas”
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading6">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                  ¿Qué pasa si detienen mi producto en aduana?
                </button>
              </div>
              <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordion">
                <div className="accordion-body">
                  En primer lugar, deberás de solicitar la fracción arancelaria del producto. Una vez que tengas la fracción arancelaria y corrobores que le solicitan el cumplimiento de la <b>NOM-142-SCFI/SS1-2014</b> deberás de contactar a una Unidad de Inspección para obtener una Constancia de Inspección.
                  <br />
                  <br />
                  Debido a que el producto no puede etiquetarse en México, deberás de etiquetar en la aduana o regresar el producto con tu proveedor para que sea etiquetado.
                  <br />
                  Te recomendamos analizar los costos de cada situación y evaluarlos.

                  <br />
                  <br />
                  Una vez que nos contactes, te brindaremos la asesoría y apoyo para que puedas liberar tu producto de la aduana. El proceso será rápido, cada minuto cuenta, por lo que te dejamos nuestro correo (contacto@cecsatrade.mx) y número de contacto (56 5019 0728)
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading7">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                  ¿Qué controles sanitarios deben cumplir los fabricantes de bebidas alcohólicas según la NOM-142?
                </button>
              </div>
              <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Los fabricantes de bebidas alcohólicas deben seguir estrictos controles sanitarios en sus instalaciones para cumplir con la NOM-142. Esto incluye:
                  <br />
                  <br />
                  -Buenas Prácticas de Manufactura (BPM)
                  <br /> -Control de calidad
                  <br /> -Registro sanitario
                  <br /> -Mantenimiento y limpieza de equipos
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading8">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                  ¿Cuáles son las sanciones por incumplir con la NOM-142 en la producción o comercialización de bebidas alcohólicas?
                </button>
              </div>
              <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordion">
                <div className="accordion-body">
                  El incumplimiento de la NOM-142 puede generar sanciones severas para los fabricantes o distribuidores, tales como:
                  <br />
                  <br /> -Multas económicas
                  <br /> -Suspensión de actividades
                  <br /> -Retiro de productos del mercado
                  <br /> -Clausura temporal o permanente
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading9">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                  ¿Qué pasa si consumo alcohól  adulterado?
                </button>
              </div>
              <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordion">
                <div className="accordion-body">
                  El consumo de este puede ocasionar síntomas como: dolor de cabeza, mareo, náuseas, vómitos, daño grave en nervio óptico (ceguera o pérdida de la visión), daños al hígado, cirrosis e incluso puede llevar a la muerte prematura.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading10">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                  ¿Cómo sé si el acohól es adulterado?
                </button>
              </div>
              <div id="collapse10" className="accordion-collapse collapse" aria-labelledby="heading10" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Puedes identificar que una bebida alcohólica ha sido adulterada si: No cuenta con un registro sanitario. No cuenta con identificación alguna. Cuando se encuentra en envases de otras bebidas.
                </div>
              </div>
            </li>
          </ul>
        </div>
        <img className='norm-info__ellipse' src="/img/ellipse-blue.svg" alt="ellipse" />
        <ServicesToOneClick />
        <ServiceSteps
          withNewsLetter={true}
        />
      </section>
    </>
  )
}

export default Nom142