/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { BenefictsAndWhoDifferent, ExcusiveAttention, JsonLd, NormInfo, References, ServiceSteps, ServicesToOneClick } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom050: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-050-SCFI-2004 - Etiquetado general';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org", "@type": "WebPage", "@id":
              "https://www.cecsatrade.mx/nom-050-scfi-2004", "url":
              "https://www.cecsatrade.mx/nom-050-scfi-2004", "name": "NOM-050-SCFI-2004",
            "description": `Conoce los lineamientos esenciales de la NOM-050-SCFI-2004 que regula la información comercial de etiquetado general productos en México, garantizando claridad y transparencia en el etiquetado para proteger a los consumidores.`, "isPartOf": {
              "@id":
                "https://www.cecsatrade.mx/#/schema/WebSite/1"
            }, "datePublished":
              "2023-01-31T08:08:40-06:00", "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": { "@id": "https://www.cecsatrade.mx/assets/050.svg" }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="Conoce los lineamientos esenciales de la NOM-050-SCFI-2004 que regula la información comercial de etiquetado general productos en México, garantizando claridad y transparencia en el etiquetado para proteger a los consumidores."
        />
      </Helmet>
      <section className='norm-info'>
        <div className='sub-header'>
          <div className='sub-header__spacer'></div>
          <div className='sub-header__spacer'></div>
          {/* <a className='sub-header__button' href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a> */}
          <a
            className='sub-header__email'
            href="mailto:asesoria@cecsatrade.mx"
            target="_blank">
            <img loading="lazy" src="/img/mail.svg" alt="Mail" />
            |
            Mail de CECSA
          </a>
        </div>
        <h1 className='norm-info__title'>NOM-050-SCFI-2004 ETIQUETADO GENERAL DE PRODUCTOS</h1>
        <div className='norm-info__header norm-info__header--050'>
          <button onClick={() => {
            const element = document.getElementById("benefits");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
          }}>Beneficios</button><a href='https://wa.me/message/YZR3TYWZRARDH1' target='_blank'>Quiero cumplir con la NOM</a>
          <button onClick={() => {
            const element = document.getElementById("preguntas-frecuentes");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
            }
          }}>Preguntas frecuentes</button>
        </div>
        <div className='norm-info__image-container'>
          {/* <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-003-asset.svg" alt="NOM 003 Logo" /> */}
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Sé el primero en saber...</h2>
            <br />
            <p>
              La <b>Norma 050 de etiquetado general de productos</b> tiene como objeto dar a conocer la <b>información comercial</b> que deben contener los productos de fabricación caional o extranjera destinados a consumidores de territorio nacional.
              <br />
              <br />
              Esta <b>Norma Oficial Mexicana</b> es aplicable a los requisitos para garantizar un correcto <b>etiquetado general de productos</b> que sea claro y entendible para la protección del consumidor del país.
            </p>
          </div>
        </div>
        <div className='norm-info__image-container norm-info__image-container--black'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center norm-info__image-container__text--small-margin'>
            <p style={{ textAlign: 'left' }}>
              Conoce los lineamientos esenciales de la <b>NOM-050-SCFI-2004</b> que <b>regula la información comercial de etiquetado general productos en México</b>, garantizando claridad y transparencia en el etiquetado para proteger a los consumidores.
            </p>
            <button onClick={() => {
              const element = document.getElementById("benefits");

              if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
              }
            }}>Beneficios</button>
          </div>
          <div className='norm-info__image-container__img-container'>
            <img className='norm-info__image-container__img-container__image' loading="lazy" src="/img/post-it.png" alt="post-it" />
          </div>
        </div>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Entrada en vigor</h2>
            <br />
            <p>
              El 01 de junio de 2004 se publicó en el <b>Diario Oficial de la Federación la NOM-050-SCFI-2004, Información comercial-Etiquetado general de productos</b>, pero entró en vigor 60 días posteriores a su publicación con fecha del  31 de julio de 2004
            </p>
            <br />
            <a className='norm-info__image-container__anchorage' href="https://dof.gob.mx/nota_detalle.php?codigo=708514&fecha=01/06/2004#gsc.tab=0" target='_blank'>Más información de esta NOM</a>
          </div>
        </div>
        <img className='norm-info__ellipse' src="/img/ellipse-black.svg" alt="ellipse" />
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Aplicación</h2>
            <br />
            <p>
              Esta norma es aplicable a todos los <b>productos nacionales</b> y de procedencia extranjera  destinados a comercialización.
              <br />
              <br />
              La <b>NOM-050-SCFI-2004</b> establecer los requisitos de <b>información comercial</b> que deben incluir los productos vendidos en México buscando garantizar que la información contenida en etiquetas, envases, instrucciones y otros elementos sea clara y veraz para la protección del consumidor. Además, promueve la transparencia al exigir que los datos  como las instrucciones de uso, advertencias, y componentes, sean presentados de manera accesible y comprensible
            </p>
          </div>
        </div>
        <ExcusiveAttention />
        <div className='norm-info__image-container norm-info__image-container--blue-050'>
          <img className='norm-info__image-container__image-background-1' loading="lazy" src="/img/background-circle-blue-1.svg" alt="backghround 1" />
          <div className='norm-info__image-container__text'>
            <h2>La NOM-050 no aplica para</h2>
            <br />
            <div className='norm-info__image-container__text__data-item'>
              <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/A.svg" alt="A" />
              <span>a) Los productos o disposiciones de información comercial en normas oficiales mexicanas específicas o alguna reglamentación vigente.</span>
            </div>
            <div className='norm-info__image-container__text__data-item-container'>
              <div className='norm-info__image-container__text__data-item'>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/B.svg" alt="B" />
                <span>b) Los productos a granel.</span>
              </div>
              <div className='norm-info__image-container__text__data-item'>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/C.svg" alt="C" />
                <span>c) Los animales vivos.</span>
              </div>
            </div>
            <div className='norm-info__image-container__text__data-item'>
              <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/D.svg" alt="D" />
              <span>d) Los libros, revistas, fascículos y las publicaciones de periódicas en cualquier presentación, incluyendo de manera enunciativa y no limitativa.</span></div>
            <div className='norm-info__image-container__text__data-item'>
              <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/E.svg" alt="E" />
              <span>e) Los demás productos que determine la autoridad competente a sus atribuciones.</span></div>
          </div>
          <img className='norm-info__image-container__image-background-2' loading="lazy" src="/img/background-circle-blue-2.svg" alt="backghround 2" />
        </div>
        <div className='norm-info__image-container norm-info__image-container--black norm-info__image-container--margin norm-info__image-container--050' style={{ marginBottom: '30rem', overflow: 'visible', zIndex: '7' }}>
          <img className='norm-info__image-container__books-050' loading="lazy" src="/img/books.png" alt="books" />
          <h2>DEFINICIONES</h2>
          <div className="norm-info__050-definitions__item">
            <span>Consumidor</span>
            <p>Persona física o moral que adquiere como destinatario final, productos. No es consumidor quien consuma productos con objeto de integrarlos en procesos de producción u otros más.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Embalaje</span>
            <p>Material que envuelve, contiene y protege los productos, para efecto de su almacenamiento y transporte</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Envase</span>
            <p>Cualquier recipiente o envoltura en el cual está contenido el producto para su venta al consumidor</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Envase múltiple</span>
            <p>Cualquier recipiente o envoltura en el que se encuentran contenidos dos o más variedades iguales de productos preenvasados, destinados para su venta al consumidor.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Envase colectivo</span>
            <p>Cualquier recipiente o envoltura en el que se encuentran contenidos dos o más variedades diferentes de productos preenvasados, destinados para su venta al consumidor.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Etiqueta</span>
            <p>Cualquier rótulo, marbete, inscripción, imagen u otra materia descriptiva o gráfica, escrita, impresa, marcada, grabada en alto o bajo relieve, adherida o sobrepuesta al producto, a su envase dependiendo las características del producto o su envase, al embalaje.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Garantía</span>
            <p>Documento donde el productor o importador se compromete a respaldar el producto contra defectos de funcionamiento, ya sea en  materiales o en la mano de obra durante su fabricación</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Instructivo</span>
            <p>Información por escrito dirigida al consumidor que explica cómo debe usarse y aprovecharse el producto.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Manual de operación</span>
            <p>Documento que proporciona la información necesaria para el ensamblado, instalación, conexión y mantenimiento del producto</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Preenvasado</span>
            <p>Proceso donde un producto es colocado en un envase de cualquier naturaleza y la cantidad del producto no puede ser alterada a menos que éste sea abierto o modificado.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Producto a granel</span>
            <p>Producto que debe pesarse, contarse o medirse en presencia del consumidor por no encontrarse preenvasado al momento de su venta.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Productos peligrosos</span>
            <p>Aquellos definidos así por las normas oficiales mexicanas y la legislación vigente.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Secretaría</span>
            <p>Secretaría de Comercio y Fomento Industrial</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Superficie principal de exhibición</span>
            <p>De conformidad con la NOM-030-SCFI, aquella parte de la etiqueta o envase a la que se le da mayor importancia para ostentar el nombre y la marca comercial del producto, excluyendo las tapas y fondos de latas, tapas, hombros y cuellos de botellas</p>
          </div>
          <img className='norm-info__050-definitions__footer' loading="lazy" src="/img/definitions-050-footer.svg" alt="footer" />
        </div>
        <BenefictsAndWhoDifferent
          title={'CERTIFICA TUS PRODUCTOS'}
          text={'Contactanos, nosotros te apoyamos en cada paso que debes realizar para cumplir conforme lo establecido de manera nacional en materia de comercial dentro de la industria.'}
        />
        <References
          items={[
            (
              <li>
                <img src="/img/list-item-050.png" alt="pen" />
                <b>NOM-008-SCFI-2002</b>, Sistema General de Unidades de Medida.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-050.png" alt="pen" />
                <b>NOM-030-SCFI-2006</b>, Información comercial- Declaración de cantidad en la etiqueta de especificaciones.
              </li>
            )
          ]}
        />
        {/* <h1 className='norm-info__title'>NOM-050-SCFI-2004</h1>
        <h2>Información comercial-Etiquetado general de
          productos.</h2>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text'>
            <h2>Objetivo</h2>
            <p>
              Esta Norma Oficial Mexicana tiene por objeto establecer la información
              comercial que deben contener los productos de fabricación nacional o
              extranjera que se destinen a los consumidores en el territorio nacional y
              establecer las características de dicha información.
            </p>
          </div>
          <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-050.svg" alt="NOM 015 Logo" />
        </div>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text'>
            <h2>Campo de aplicación</h2>
            <p>
              Esta Norma es aplicable a todos los productos de fabricación nacional y
              extranjera destinados a los consumidores en territorio nacional.
            </p>
          </div>
        </div>
        <div className='norm-info__image-container'>
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/no-aplica.svg"
            alt="NOM Logo"
          />
          <div className='norm-info__image-container__text'>
            <p>
              <h2 className="norm-info__container__sub-title" id="no-aplica">La norma no se
                aplica a</h2>
              a) Los productos que estén sujetos a disposiciones de información
              comercial contenidas en normas oficiales mexicanas específicas o en alguna
              otra reglamentación vigente;
              <br />b) Los productos a granel;
              <br />c) Los animales vivos;
              <br />d) Los libros, revistas, fascículos y las publicaciones periódicas
              en cualquier presentación, incluyendo de manera enunciativa y no
              limitativa, discos magnéticos y compactos, cintas y artículos análogos;
              <br />e) Los demás productos que determine la autoridad competente,
              conforme a sus atribuciones.
            </p>
          </div>
        </div>
        <blockquote cite="https://www.dof.gob.mx/nota_detalle.php?codigo=708514&fecha=01/06/2004#gsc.tab=0">
          <div className="norm-info__container__image-container">
            <div>
              <p className="norm-info__container__text">
                <h2 className="norm-info__container__sub-title">Definiciones</h2>
                <dl>

                  <dt>Consumidor</dt>
                  <dd>Persona física o moral que adquiere o disfruta, como destinatario
                    final, productos. No es consumidor quien adquiera, almacene, utilice
                    o consuma productos con objeto de integrarlos en procesos de
                    producción, transformación, comercialización o prestación de
                    servicios a terceros.</dd>

                  <dt>Embalaje</dt>
                  <dd>Material que envuelve, contiene y protege los productos, para
                    efecto de su almacenamiento y transporte.</dd>

                </dl>
              </p>
            </div>
            <img
              loading="lazy"
              className="norm-info__container__image-container__image"
              src="/img/050-image-1.svg"
              alt="NOM Logo"
            />
          </div>
        </blockquote>
        <blockquote cite="https://www.dof.gob.mx/nota_detalle.php?codigo=708514&fecha=01/06/2004#gsc.tab=0">
          <div className="norm-info__container__image-container">
            <div>
              <p className="norm-info__container__text">
                <dl>

                  <dt>Envase</dt>
                  <dd>Cualquier recipiente o envoltura en el cual está contenido el
                    producto para su venta al consumidor.Envase múltiple: Cualquier
                    recipiente o envoltura en el que se encuentran contenidos dos o
                    más variedades iguales de productos preenvasados, destinados
                    para su venta al consumidor en dicha presentación.</dd>

                  <dt>Envase colectivo</dt>
                  <dd>Cualquier recipiente o envoltura en el que se encuentran
                    contenidos dos o más variedades diferentes de productos
                    preenvasados, destinados para su venta al consumidor en dicha
                    presentación.</dd>

                  <dt>Etiqueta</dt>
                  <dd>Cualquier rótulo, marbete, inscripción, imagen u otra materia
                    descriptiva o gráfica, escrita, impresa, estarcida, marcada,
                    grabada en alto o bajo relieve, adherida o sobrepuesta al
                    producto, a su envase o, cuando no sea posible por las
                    características del producto o su envase, al embalaje.</dd>

                  <dt>Garantía</dt>
                  <dd>Documento mediante el cual el productor o importador se
                    compromete a respaldar el producto contra defectos de
                    funcionamiento, de los materiales o de la mano de obra empleados
                    en la fabricación del producto.</dd>

                  <dt>Instructivo</dt>
                  <dd>Información por escrito dirigida al consumidor que explica
                    cómo debe usarse y aprovecharse el producto.</dd>

                  <dt>Manual de operación</dt>
                  <dd>Documento que proporciona la información necesaria para el
                    ensamblado, instalación, conexión y mantenimiento del producto.</dd>

                  <dt>Preenvasado</dt>
                  <dd>Proceso en virtud del cual un producto es colocado en un
                    envase de cualquier naturaleza, sin encontrarse presente el
                    consumidor, y la cantidad de producto contenida en el envase no
                    puede ser alterada a menos que éste sea abierto o modificado.</dd>

                  <dt>Producto a granel</dt>
                  <dd>Producto que debe pesarse, contarse o medirse en presencia del
                    consumidor por no encontrarse preenvasado al momento de su
                    venta.</dd>

                  <dt>Productos peligrosos</dt>
                  <dd>Aquellos definidos así por las normas oficiales mexicanas y la
                    legislación vigente.</dd>


                  <dt>Secretaría</dt>
                  <dd>Secretaría de Comercio y Fomento Industrial.</dd>

                  <dt>Superficie principal de exhibición</dt>
                  <dd>De conformidad con la NOM-030-SCFI, aquella parte de la etiqueta o
                    envase a la que se le da mayor importancia para ostentar el nombre y
                    la marca comercial del producto, excluyendo las tapas y fondos de
                    latas, tapas de frascos, hombros y cuellos de botellas.</dd>

                </dl>
              </p>
            </div>
          </div>
        </blockquote> */}
        <NormInfo
          DOFDate='01/06/2004'
          VigorDate='31/07/2004'
          responsible='SECRETARIA DE ECONOMIA'
        />

        <div className='norm-info__questions' id='preguntas-frecuentes'>
          <h2 className="norm-info__container__sub-title">Preguntas frecuentes</h2>
          <ul className="accordion norm-info__container__not-transform-ul" id="accordion">
            <li className="accordion-item">
              <div id="heading1">
                <button className="accordion-button norm-info__container__accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                  ¿Qué productos están sujetos a la NOM-050-SCFI-2004?
                </button>
              </div>
              <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Esta Norma Oficial Mexicana aplica para todos aquellos productos que sean fabricados en territorio nacional y que sean provenientes del extranjero destinados a consumidores mexicanos.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                  ¿Cuál es la diferencia entre la NOM 050 y la NOM 051?
                </button>
              </div>
              <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordion">
                <div className="accordion-body">
                  La diferencia principal de estas dos Normas Oficiales Mexicanas es que la NOM-050 se establece para el etiquetado de productos de consumo general que no sean alimentos esto porque algunos  alimentos ya tienen una norma especifica, lo cual la NOM-051 se encarga del etiquetado de alimentos y bebidas no alcohólicas preenvasadas con un enfoque hacia la salud del consumidor.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading3">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                  ¿Qué organismos regulan la NOM-050-SCFI-2004?
                </button>
              </div>
              <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordion">
                <div className="accordion-body">
                  El organismo encargado de regular esta norma es la Secretaría de Economía
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading4">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                  ¿Qué hago si me detienen mi producto en aduana?
                </button>
              </div>
              <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordion">
                <div className="accordion-body">
                  En primer lugar, deberás de solicitar la fracción arancelaria del producto. Una vez que tengas la fracción arancelaria y corrobores que le solicitan el cumplimiento de la <b>NOM-050-SCFI-2004</b>, deberás de contactar a una Unidad de Inspección para obtener una Constancia de Inspección. Debido a que el producto no puede etiquetarse en México, deberás de etiquetar en la aduana o regresar el producto con tu proveedor para que sea etiquetado. <br /><br />Te recomendamos analizar los costos de cada situación y evaluarlos. <br /><br />Una vez que nos contactes, te brindaremos la asesoría y apoyo para que puedas liberar tu producto de la aduana. El proceso será rápido, cada minuto cuenta, por lo que te dejamos nuestro correo (contacto@cecsatrade.mx) y número de contacto (56 5019 0728)
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading5">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                  ¿Qué información debe incluirse en las etiquetas según la NOM-050-SCFI-2004?
                </button>
              </div>
              <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Las etiquetas deben incluir, entre otros aspectos: nombre o denominación del producto, instrucciones de uso, advertencias y precauciones, país de origen, nombre o razón social del fabricante o importador, y contenido neto. <br />La información debe estar en español y ser clara y comprensible.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading6">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                  ¿Cómo afecta la NOM-050-SCFI-2004 a los productos importados?
                </button>
              </div>
              <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordion">
                <div className="accordion-body">
                  El Servicio Nacional de Información de Comercio Exterior, SNICE, publicó el acuerdo por el que la Secretaría de Economía emite reglas y criterios de carácter general en materia de comercio exterior, como complemento a los criterios de interpretación de las NOMs de etiquetado sanitario y comercial publicados en 2020, donde quedan excluidas las importaciones de materias primas, insumos o productos a granel no destinados al consumidor final. <br /><br />Es decir, se trata principalmente de mercancías utilizadas como parte de un proceso productivo o de transformación. <br /><br />Asimismo, dentro de los criterios también se incluye a aquellos productos envasados que no se presentan en el recipiente definitivo y final que aparecerá frente a los ojos del consumidor para ser adquirido como producto en punto de venta, y donde tampoco deberá exigirse la demostración del cumplimiento de las NOMs comerciales ante la UIA (Unidad de Inspección Acreditada).
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading7">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                  ¿Qué sucede si un producto no cumple con la NOM-050-SCFI-2004?
                </button>
              </div>
              <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordion">
                <div className="accordion-body">
                  De acuerdo a la Ley  Federal de la Metrología y Normalización, en el articulo 112, nos dice que si un producto no cumple con la NOM puede generar multas, clausura temporal o definitiva, que podrá ser parcial o total, arresto hasta por treinta y seis horas, suspensión o revocación de la autorización, aprobación, o registro según corresponda y suspensión o cancelación del documento donde consten los resultados de la evaluación de la conformidad, así como de la autorización del uso de contraseñas y marcas registradas.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading8">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                  ¿Qué requisitos deben cumplir los productos que aplican a  la NOM-050-SCFI-2004?
                </button>
              </div>
              <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordion">
                <div className="accordion-body">
                  El etiquetado de los productos que aplique ha esta NOM deben de tener información en la cual le indique a los consumidores de donde proviene, fecha de caducidad, cual es el contenido, los ingredientes que contiene, información de la empresa, nombre del producto, contenido neto.
                </div>
              </div>
            </li>
          </ul>
        </div>
        <img className='norm-info__ellipse' src="/img/ellipse-blue.svg" alt="ellipse" />
        <ServicesToOneClick />
        <ServiceSteps
          withNewsLetter={true}
        />
      </section>
    </>
  )
}

export default Nom050