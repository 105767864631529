import './styles.scss';

const AllDigital: React.FC = () => {
  return (
    <section className='all-digital'>
      <h2>Todo de manera digital</h2>
      <div className='all-digital__text'>
        <p>

          <img src="/img/check.svg" alt="check" className='main__section-3__item__list__item__img' />
          Independientemente de dónde te encuentres, gestionar todos tus servicios desde nuestra aplicación.
          <br />
          <br />

          <img src="/img/check.svg" alt="check" className='main__section-3__item__list__item__img' />Tramite en línea 24 horas
          <br />
          <br />

          <img src="/img/check.svg" alt="check" className='main__section-3__item__list__item__img' />Por internet - solo necesitas 1 computadora con acceso a internet.
          <br />
          <br />

          <img src="/img/check.svg" alt="check" className='main__section-3__item__list__item__img' />Un un lapso de 2-3 días, tendrás tu informe de ersultados.
        </p>
        <img className='all-digital__image' src="/img/digital.svg" alt="digital" />
      </div>
    </section>
  )
}

export default AllDigital