import './styles.scss';

interface Props {
  text: string
}

const Paragraph: React.FC<Props> = (props) => {
  const {
    text
  } = props;

  return (
    <p className='text'>{text}</p>
  )
}

export default Paragraph