import { Link } from 'react-router-dom'
import Button from '../Button'
import './styles.scss'

export const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer__header'>
        <h3 className='footer__header__title'>Solicita una sesión con un experto</h3>
        <Button
          text={'Contáctanos'}
          onClick={() => { }}
          type={'black'}
          anchor={true}
          href="mailto:asesoria@cecsatrade.mx"
          target="_blank"
        />
      </div>
      <div className='footer__column footer__column--space-between'>
        <div>
          <h2>CECSAS TRADE</h2>
          <p>Somos la unidad de inspección que digitaliza todos sus procesos para simplificar tu trabajo.</p>
        </div>
        <div className='footer__column__social-media'>
          <a href='https://www.facebook.com/CECSATRADE/?locale=es_LA' target='_blank' rel="noreferrer">
            <img src="/img/facebook.svg" alt="facebook" />
          </a>
          <a href='https://www.tiktok.com/@cecsatrade' target='_blank' rel="noreferrer">
            <img src="/img/tiktok.svg" alt="tiktok" />
          </a>
          <a href='/' target='_blank' rel="noreferrer">
            <img src="/img/x.svg" alt="x" />
          </a>
          <a href='https://www.instagram.com/cecsa_trade_/?hl=es' target='_blank' rel="noreferrer">
            <img src="/img/instagram.svg" alt="instagram" />
          </a>
          <a href='https://www.youtube.com/@CECSATrade_MX' target='_blank' rel="noreferrer">
            <img src="/img/youtube.svg" alt="youtube" />
          </a>
          <a href='https://mx.linkedin.com/company/cecsatrade' target='_blank' rel="noreferrer" >
            <img src="/img/linkedin.svg" alt="linkedin" />
          </a>
          <a href="https://wa.me/message/YZR3TYWZRARDH1" target='_blank' rel="noreferrer">
            <img src="/img/whatsapp.svg" alt="whatsapp" />
          </a>
        </div>
      </div>
      <div className='footer__column'>
        <h2>Contacto</h2>
        <div className='footer__column__item'>
          <img src="/img/cellphone.svg" alt="cellphone" />
          <a itemProp="url" href="https://wa.me/message/YZR3TYWZRARDH1" target='_blank' rel='noreferrer'>56 5019 0728</a>
        </div>
        <div className='footer__column__item'>
          <img src="/img/email.svg" alt="email" />
          <a itemProp="email" href="mailto:asesoria@cecsatrade.mx" target='_blank' rel='noreferrer'>asesoria@cecsatrade.mx</a>
        </div>
        <div className='footer__column__item'>
          <img src="/img/address.svg" alt="address" />
          Zapopan Jalisco
        </div>
      </div>
      <div className='footer__column footer__column--navigation'>
        <h2>Menú de navegación</h2>
        <Link to={'/'} >
          <span>Inicio</span>
        </Link>
        <a href="/#norms">
          <span>Normas</span>
        </a>
        <Link to={'/services'} >
          <span>Servicios</span>
        </Link>

        <a href="https://blog.cecsatrade.mx" target='_blank' rel="noreferrer">
          <span>Blog</span>
        </a>
        <Link to={'/complaints_and_appeals'} >
          <span>Quejas y apelaciones</span>
        </Link>
        <Link to={'/bolsa_de_trabajo'} >
          <span>Bolsa de trabajo</span>
        </Link>
        <Link to={'/practicas_profesionales'} >
          <span>Prácticas profesionales</span>
        </Link>
        <Link to={'/alienzas-comerciales'} >
          <span>Alianzas comerciales</span>
        </Link>
        <Link to={'/notice_of_confidentiality'} >
          <span>Aviso de confidencialidad</span>
        </Link>
      </div>
      <span className='footer__text-bottom'>Av. Cruz del Sur 3195, tercer piso, colonia Loma Bonita Ejidal, Zapopan, Jalisco, México</span>
    </footer>
  )
}

export default Footer