
import './styles.scss';
import Button from '../Button';
import Title from '../Title';
import { useNavigate } from 'react-router-dom';

interface Props {
  white?: boolean
}

const ServicesToOneClick: React.FC<Props> = (props) => {
  const {
    white = false
  } = props;

  const navigate = useNavigate();

  return (
    <section className={`main__section-3 main__section-3${white ? '--white' : ''}`}>
      <div className='main__section-3__item'>
        <Title title='Todos los servicios a 1 click de distancia' />
        <p className='main__section-3__item__data'>Diseñamos nuestros procesos para que puedas tener la información disponible desde tu computadora o teléfono.</p>
        <ul className='main__section-3__item__list'>
          <li className='main__section-3__item__list__item'>
            <img src="/img/check.svg" alt="check" className='main__section-3__item__list__item__img' />
            Recibe notificaciones al instante
          </li>
          <li className='main__section-3__item__list__item'>
            <img src="/img/check.svg" alt="check" className='main__section-3__item__list__item__img' />
            Obtén tus certificados el mismo día
          </li>
          <li className='main__section-3__item__list__item'>
            <img src="/img/check.svg" alt="check" className='main__section-3__item__list__item__img' />
            Cumple con la norma y evita multas
          </li>
        </ul>
        <Button
          text={'Solicita una cotización'}
          onClick={() => navigate('/')}
          type={'black'}

          anchor={true}
          href="mailto:asesoria@cecsatrade.mx"
          target="_blank"
        />
      </div>
      <div className='main__section-3__item main__section-3__item--small'>
        <img src="/img/computer.svg" alt="computer" className='main__section-3__item__img' />
      </div>
    </section>
  )
}

export default ServicesToOneClick